import { requestAPI } from "../RequestAPI";
import URL from "../UrlsEnum";

export async function FetchSelfInfos() {
	const data = await requestAPI(URL.USER, "GET");
	return data;
}

export async function UpdateUser({ name, language }) {
	return await requestAPI(URL.USER, "PUT", {
		name,
		language,
	});
}

export async function ForceCheckEmailVerification() {
	await requestAPI(URL.USER_VERIFY_EMAIL, "GET");
}

export async function GeneratePin() {
	const data = await requestAPI(URL.RESET_PIN, "POST", {});
	return data;
}
