import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Permissions from "../../../common/config/permissions";
import { TreeContext } from "../../../common/context/TreeContext";
import Restricted from "../../../common/context/hooks/Restricted";
import {
	AccordionCatalog,
	AccordionGroup,
	AccordionOrders,
} from "../../../common/layouts/Tree/AccordionTree";
import { findPreviousLevelIds } from "../../../portail/layouts/Tree/functions";
const StoreGroupTree = ({
	parent,
	group,
	opengroups,
	setOpenGroups,
	setLastSelected,
	lastSelected,
	handleClickInsideModal = () => {
		return;
	},
}) => {
	const navigate = useNavigate();
	const AddToListOrDeleteIfExists = (setFunction, list, idToCheck) => {
		const isGroupIncluded = list.includes(idToCheck);
		setFunction(
			isGroupIncluded
				? list.filter((el) => el !== idToCheck)
				: [...list, idToCheck]
		);
	};
	const AddToListOrNothingIfExists = (setFunction, list, idToCheck) => {
		const isGroupIncluded = list.includes(idToCheck);
		setFunction(isGroupIncluded ? list : [...list, idToCheck]);
	};

	const renderGroups = (groupData) => {
		if (!groupData || groupData.length === 0) {
			return null;
		}
		if (groupData.type === "CUSTOM") return null;

		return (
			<div style={{ direction: "ltr" }}>
				{groupData?.map((group, index) => {
					if (group.type === "CUSTOM") return null;
					return (
						<AccordionGroup
							parent={parent}
							title={group.name}
							isOpen={
								parent ? true : opengroups?.includes(group.id)
							}
							selected={
								lastSelected.type === "group" &&
								lastSelected.id === group.id
							}
							collapse={() => {
								parent && setOpenGroups([]);
							}}
							openTree={() =>
								AddToListOrDeleteIfExists(
									setOpenGroups,
									opengroups,
									group.id
								)
							}
							action={() => {
								AddToListOrNothingIfExists(
									setOpenGroups,
									opengroups,
									group.id
								);
								navigate(`/store/${group.id}/catalog`);
								setLastSelected({
									type: "catalog",
									id: group.id,
								});
								handleClickInsideModal();
							}}
							key={index}
						>
							<Restricted
								to={Permissions.STORE_CATALOG_VIEW}
								groupId={group.id}
							>
								<Restricted
									to={Permissions.STORE_ORDER_CREATE}
									groupId={group.id}
								>
									<AccordionCatalog
										title="catalog"
										selected={
											lastSelected.type === "catalog" &&
											lastSelected.id === group.id
										}
										action={() => {
											navigate(
												`/store/${group.id}/catalog`
											);
											setLastSelected({
												type: "catalog",
												id: group.id,
											});
											handleClickInsideModal();
										}}
									/>
								</Restricted>
							</Restricted>

							<Restricted
								to={Permissions.STORE_ORDER_VIEW}
								groupId={group.id}
							>
								<AccordionOrders
									title="orders"
									selected={
										lastSelected.type === "orders" &&
										lastSelected.id === group.id
									}
									action={() => {
										navigate(`/store/${group.id}/orders`);
										setLastSelected({
											type: "orders",
											id: group.id,
										});
										handleClickInsideModal();
									}}
								/>
							</Restricted>
							{group.group && group.group.length > 0 && (
								<StoreGroupTree
									parent={false}
									group={group.group}
									opengroups={opengroups}
									setOpenGroups={setOpenGroups}
									lastSelected={lastSelected}
									setLastSelected={setLastSelected}
									handleClickInsideModal={
										handleClickInsideModal
									}
								/>
							)}
						</AccordionGroup>
					);
				})}
			</div>
		);
	};

	return <div>{renderGroups(group)}</div>;
};
function StoreGroupTag({ data, handleClickInsideModal }) {
	const [opengroups, setOpenGroups] = useState([]);
	const [lastSelected, setLastSelected] = useState({ type: "", id: 0 });

	const location = useLocation();
	const { updateTree } = useContext(TreeContext);
	useEffect(() => {
		const GetPathIfListAreEmpty = () => {
			const AllArgs = location.pathname.split("/");
			let groupid = parseInt(AllArgs[2]);
			let pathName = AllArgs[3];
			const pathtree = findPreviousLevelIds(data, groupid);
			if (groupid > 0) {
				if (opengroups?.length === 0) {
					setOpenGroups(pathtree);
				}
			}
			setLastSelected({
				type: pathName,
				id: groupid,
			});
		};

		GetPathIfListAreEmpty();
	}, [updateTree]);

	return (
		<div
			style={{
				width: "100%",
				padding: "5px",
				overflowX: "hidden",
				height: "75vh",
				overflowY: "auto",
				direction: "rtl",
			}}
		>
			<div className=" overflow-auto">
				<StoreGroupTree
					parent={true}
					group={data}
					opengroups={opengroups}
					setOpenGroups={setOpenGroups}
					lastSelected={lastSelected}
					setLastSelected={setLastSelected}
					handleClickInsideModal={handleClickInsideModal}
				/>
			</div>
		</div>
	);
}

export default StoreGroupTag;
