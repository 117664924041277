import auth from "../../common/config/firebase";

export const requestAPI = async (
	url,
	method,
	body,
	signal,
	isFormData = false,
	extraHeaders = {}
) => {
	try {
		const token = await auth.currentUser.getIdToken();

		const headers = {
			Authorization: "Bearer " + token,
			...extraHeaders,
		};

		if (!isFormData) {
			headers["Content-Type"] = "application/json";
		}

		let fetchOptions = {
			timeout: 5000,
			method: method,
			headers: headers,
			signal: signal || undefined,
		};

		if (isFormData) {
			fetchOptions.body = body;
		} else {
			fetchOptions.body = JSON.stringify(body);
		}

		const response = await fetch(url, fetchOptions);

		const responseText = await response.text();

		try {
			const responseBody = JSON.parse(responseText);
			return { success: response.ok, data: responseBody };
		} catch (e) {
			return { success: response.ok, data: undefined };
		}
	} catch (e) {
		return { success: false };
	}
};

export const requestAPIToDownload = async (url, method, body) => {
	try {
		const token = await auth.currentUser.getIdToken();

		const response = await fetch(url, {
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			method: method,
			body: JSON.stringify(body),
		});

		const fileName =
			response.headers
				?.get("Content-Disposition")
				?.split("filename=")[1] ?? "unknown";

		const responseBlob = await response.blob();

		const fileURL = window.URL.createObjectURL(responseBlob);
		const link = document.createElement("a");
		link.href = fileURL;
		link.setAttribute("download", fileName);
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
		return { success: response.ok };
	} catch (e) {
		return { success: false };
	}
};
