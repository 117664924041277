import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Pagination from "../../../common/layouts/Global/Pagination";
import { useAuth } from "../../../common/context/AuthContext";

function TableDraggable({
	className,
	columns,
	rows,
	isLoading,
	pagination,
	currentPage,
	handlePageChange,
	handleDragFunction,
	canDrag,
	setCanDrag,
	groupId,
}) {
	const FormatePaginate = () => {
		const elementosHtml = document.querySelectorAll(".pagination-link");

		elementosHtml.forEach((elemento) => {
			const numero = elemento.textContent;

			const regexInt = /^\d+$/;

			const checkInt = regexInt.test(numero);

			if (checkInt) {
				const numeroFormatado = parseInt(numero).toLocaleString();
				elemento.textContent = numeroFormatado;
			}
		});
	};
	const { staff, inGroup } = useAuth();
	useEffect(() => {
		FormatePaginate();
	}, []);

	const RefreshPaginate = (item) => {
		handlePageChange(item);
		setTimeout(function () {
			FormatePaginate();
		}, 1);
	};
	const [draggingIndex, setDraggingIndex] = useState(null);
	const [overIndex, setOverIndex] = useState(null);
	const handleItemDragStart = (event, index) => {
		event.dataTransfer.setData("text/plain", roles[index][0].toString());
		setDraggingIndex(index);
	};

	const [dropPosition, setDropPosition] = useState(null);
	const handleItemDragOver = (event, index) => {
		event.preventDefault();
		const draggableElement = event.currentTarget;
		const rect = draggableElement.getBoundingClientRect();
		const halfHeight = rect.height / 2;

		if (event.clientY - rect.top < halfHeight) {
			setDropPosition("above");
		} else {
			setDropPosition("below");
		}

		setOverIndex(index);
	};

	const [roles, setRoles] = useState(rows);
	const handleItemDrop = async (event) => {
		event.preventDefault();
		if (draggingIndex === null || overIndex === null) return;
		if (draggingIndex === overIndex) return;
		let updatedRoles = [...roles];
		if (!staff() && inGroup(groupId)) {
			updatedRoles = [...roles.splice(1)];
		}

		const [draggedRole] = updatedRoles.splice(draggingIndex, 1);

		let insertAt;
		if (dropPosition === "above" && draggingIndex > overIndex) {
			insertAt = overIndex;
		} else if (
			dropPosition === "above" &&
			draggingIndex === overIndex - 1
		) {
			insertAt = overIndex - 1;
		} else if (
			dropPosition === "above" &&
			draggingIndex !== overIndex - 1
		) {
			insertAt = overIndex - 1;
		} else {
			insertAt = overIndex > draggingIndex ? overIndex : overIndex + 1;
		}

		updatedRoles.splice(insertAt, 0, draggedRole);
		let currentRank = Math.min(...updatedRoles.map((role) => role[1]));

		for (let i = 0; i < updatedRoles.length; i++) {
			updatedRoles[i][1] = currentRank++;
		}
		setRoles(updatedRoles);
		setDraggingIndex(null);
		setOverIndex(null);
		handleDragFunction(updatedRoles);
	};

	const handleItemDragEnd = () => {
		setDraggingIndex(null);
		setOverIndex(null);
		setDropPosition(null);
	};
	useEffect(() => {
		setRoles(rows);
	}, [rows]);
	if (!rows) return null;
	return (
		<>
			<div
				style={{ userSelect: "none" }}
				className={classNames(
					"overflow-auto h-full w-full rounded-lg",
					{
						"blur-[2px]": isLoading,
					},
					className
				)}
			>
				<div style={{ display: "flex" }} id="maintable">
					<table className="w-full">
						<thead
							draggable={false}
							className="text-xs text-left uppercase sticky top-0 bg-white z-10"
						>
							<tr className=" border-b-[1px] border-b-third ">
								{columns?.map((column, index) => (
									<th
										className="px-2 text-[10px] font-bold"
										key={index}
									>
										{column}
									</th>
								))}
							</tr>
						</thead>

						<tbody>
							{staff() || !inGroup(groupId) ? (
								roles.map((role, index) => {
									const isOddRow = index % 2 === 1;
									const notLastRow =
										index !== rows?.length - 1;
									const isOverRow = index === overIndex;
									const dropAboveAndOver =
										dropPosition === "above" && isOverRow;
									const dropBelowAndOver =
										dropPosition === "below" && isOverRow;
									const roleWithoutId = role.slice(1);
									return (
										<React.Fragment key={role[0]}>
											{
												<tr
													draggable={canDrag}
													onDragStart={(event) =>
														handleItemDragStart(
															event,
															index
														)
													}
													onDragOver={(event) =>
														handleItemDragOver(
															event,
															index
														)
													}
													onDrop={handleItemDrop}
													onDragEnd={
														handleItemDragEnd
													}
													key={index}
													className={classNames(
														"text-xs bg-opacity-80  ",
														{
															"hover:bg-kupablue/10 hover:cursor-grab":
																canDrag,
															"bg-white dark:bg-slate-900":
																isOddRow,
															"border-b border-slate-300":
																notLastRow &&
																!isOverRow,
															"border-t-2 border-t-red-500 border-b border-slate-300":
																dropAboveAndOver,
															"border-b-2 border-b-red-500":
																dropBelowAndOver,
															"border-b-0":
																dropAboveAndOver &&
																!notLastRow,
														}
													)}
												>
													{roleWithoutId?.map(
														(value, index) => (
															<td
																className="px-1"
																key={index}
															>
																{value}
															</td>
														)
													)}
												</tr>
											}
										</React.Fragment>
									);
								})
							) : (
								<>
									<tr>
										{roles[0]
											?.slice(1)

											.map((value, index) => (
												<td
													className="text-xs px-1"
													key={index}
												>
													{value}
												</td>
											))}
									</tr>
									{roles.slice(1).map((role, index) => {
										const isOddRow = index % 2 === 1;
										const notLastRow =
											index !== rows?.length - 1;
										const isOverRow = index === overIndex;
										const dropAboveAndOver =
											dropPosition === "above" &&
											isOverRow;
										const dropBelowAndOver =
											dropPosition === "below" &&
											isOverRow;
										const roleWithoutId = role.slice(1);
										return (
											<React.Fragment key={role[0]}>
												{
													<tr
														draggable={canDrag}
														onDragStart={(event) =>
															handleItemDragStart(
																event,
																index
															)
														}
														onDragOver={(event) =>
															handleItemDragOver(
																event,
																index
															)
														}
														onDrop={handleItemDrop}
														onDragEnd={
															handleItemDragEnd
														}
														key={index}
														className={classNames(
															"text-xs bg-opacity-80  ",
															{
																"hover:bg-kupablue/10 hover:cursor-grab":
																	canDrag,
																"bg-white dark:bg-slate-900":
																	isOddRow,
																"border-b border-slate-300":
																	notLastRow &&
																	!isOverRow,
																"border-t-2 border-t-red-500 border-b border-slate-300":
																	dropAboveAndOver,
																"border-b-2 border-b-red-500":
																	dropBelowAndOver,
																"border-b-0":
																	dropAboveAndOver &&
																	!notLastRow,
															}
														)}
													>
														{roleWithoutId?.map(
															(value, index) => (
																<td
																	className="px-1"
																	key={index}
																>
																	{value}
																</td>
															)
														)}
													</tr>
												}
											</React.Fragment>
										);
									})}
								</>
							)}
						</tbody>
					</table>
				</div>
			</div>

			{pagination && (
				<div className="flex justify-center mt-4">
					<Pagination
						currentPage={currentPage}
						pageCount={pagination.pageCount}
						onPageChange={(e) => RefreshPaginate(e.selected + 1)}
					/>
				</div>
			)}
		</>
	);
}

export default TableDraggable;
