import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useAuth } from "../context/AuthContext";
import LoadingSpinner from "../layouts/Global/LoadingSpinner";
import App from "../views/App";
import Login from "../views/Login";

function AppContainer() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [loadingCustomToken, setLoadingCustomToken] = useState(false);

	const { firebaseUser, kupaUser, loginWithCustomToken } = useAuth();

	useEffect(() => {
		const token = searchParams.get("firebase_custom_token");

		const handleCustomToken = async (token) => {
			setLoadingCustomToken(true);
			try {
				await loginWithCustomToken(token);
				setSearchParams({});
			} catch (error) {
			} finally {
				setLoadingCustomToken(false);
			}
		};

		if (token) {
			handleCustomToken(token);
		}
	}, [loginWithCustomToken, searchParams, setSearchParams]);

	return (
		<div className="font-kupaFont">
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar
				newestOnTop={true}
				closeOnClick={true}
				pauseOnFocusLoss
				draggable={true}
				pauseOnHover={false}
			/>

			{loadingCustomToken ? (
				<LoadingSpinner size={200} />
			) : firebaseUser ? (
				kupaUser ? (
					<App />
				) : (
					<LoadingSpinner size={200} />
				)
			) : (
				<Login />
			)}
		</div>
	);
}

export default AppContainer;
