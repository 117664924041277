import classNames from "classnames";
import React, { useEffect } from "react";
import Pagination from "../Global/Pagination";

function Table({
	className,
	columns,
	rows,
	isLoading,
	pagination,
	currentPage,
	handlePageChange,
}) {
	const FormatePaginate = () => {
		const elementosHtml = document.querySelectorAll(".pagination-link");

		elementosHtml.forEach((elemento) => {
			const numero = elemento.textContent;

			const regexInt = /^\d+$/;

			const checkInt = regexInt.test(numero);

			if (checkInt) {
				const numeroFormatado = parseInt(numero).toLocaleString();
				elemento.textContent = numeroFormatado;
			}
		});
	};

	useEffect(() => {
		FormatePaginate();
	}, []);

	const RefreshPaginate = (item) => {
		handlePageChange(item);
		setTimeout(function () {
			FormatePaginate();
		}, 1);
	};

	if (!rows) return null;

	return (
		<>
			<div
				className={classNames(
					"overflow-auto h-full w-full rounded-lg",
					{
						"blur-[2px]": isLoading,
					},
					className
				)}
			>
				<div style={{ display: "flex" }} id="maintable">
					<table className="w-full">
						<thead className="text-xs text-left uppercase sticky top-0 bg-white z-10">
							<tr className=" border-b-[1px] border-b-third ">
								{columns?.map((column, index) => (
									<th
										className="px-2 text-[10px] 2xl:text-xs font-bold"
										key={index}
									>
										{column}
									</th>
								))}
							</tr>
						</thead>

						<tbody>
							{rows?.map((values, index) => {
								return (
									<tr
										key={index}
										className={classNames(
											"text-xs bg-opacity-80 ",
											{
												"bg-backgroundgray dark:bg-slate-900":
													index % 2 === 0,
												"border-b border-slate-300":
													index !== rows?.length - 1,
											}
										)}
									>
										{values?.map((value, index) => (
											<td className="px-1" key={index}>
												{value}
											</td>
										))}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>

			{pagination && (
				<div className="flex justify-center mt-4">
					<Pagination
						currentPage={currentPage}
						pageCount={pagination.pageCount}
						onPageChange={(e) => RefreshPaginate(e.selected + 1)}
					/>
				</div>
			)}
		</>
	);
}

export default Table;
