import { useTranslation } from "react-i18next";

function useImagePath(imageName, isMobile = false) {
	const { i18n } = useTranslation();

	const lang = i18n.language.split("-")[0];
	return require(`../../assets/logo/${lang}/${imageName}${
		isMobile ? "_mobile" : ""
	}.png`);
}

export default useImagePath;
