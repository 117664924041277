import {
	faBook,
	faCheck,
	faLock,
	faMagnifyingGlass,
	faPen,
	faPlus,
	faWrench,
	faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

export function RoundButtonPlus({ functionCall, className, icon = undefined }) {
	return (
		<button
			className={classNames(
				`flex-auto text-xs m-[5px] w-[25px] h-[25px]  border-t-none  rounded-full  shadow-md  bg-kupabluedark/[80%] text-white hover:shadow-md hover:bg-kupablue/60`,
				className
			)}
			onClick={functionCall}
		>
			<FontAwesomeIcon icon={faPlus} size="lg"></FontAwesomeIcon>
		</button>
	);
}

export function RoundButtonXMark({ functionCall, className }) {
	return (
		<button
			className={classNames(
				`flex-auto text-xs m-[5px] w-[25px] h-[25px] max-w-[25px]  border-t-none rounded-full shadow-md  bg-kupabluedark/[80%] text-white hover:shadow-md hover:bg-kupablue/60`,
				className
			)}
			onClick={functionCall}
		>
			<FontAwesomeIcon icon={faXmark} size="lg"></FontAwesomeIcon>
		</button>
	);
}
export function RoundButtonXMarkAbsolute({ functionCall, className }) {
	return (
		<button
			className={classNames(
				`absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 flex-auto rounded-full shadow-md bg-kupabluee hover:bg-kupabluelight text-white p-1 hover:shadow-md `,
				className
			)}
			onClick={functionCall}
		>
			<FontAwesomeIcon
				className="px-[6px]"
				icon={faXmark}
			></FontAwesomeIcon>
		</button>
	);
}
export function RoundButtonPen({ functionCall, className }) {
	return (
		<button
			className={classNames(
				` text-xs m-[5px] w-[25px] h-[25px] max-w-[25px]  border-t-none rounded-full shadow-md  bg-kupabluedark/[80%] text-white hover:shadow-md hover:bg-kupablue/60`,
				className
			)}
			onClick={functionCall}
		>
			<FontAwesomeIcon icon={faPen} size="sm"></FontAwesomeIcon>
		</button>
	);
}
export function RoundButtonAccept({ functionCall, className }) {
	return (
		<button
			className={classNames(
				`font-bold text-xs m-[5px] w-[25px] h-[25px] max-w-[25px]  border-t-none rounded-full shadow-md  bg-kupabluedark/[80%] text-white hover:shadow-md hover:bg-kupablue/60`,
				className
			)}
			onClick={functionCall}
		>
			<FontAwesomeIcon
				className="font-bold"
				icon={faCheck}
				size="md"
			></FontAwesomeIcon>
		</button>
	);
}

export function RoundButtonWrench({
	functionCall,
	className,
	icon = undefined,
}) {
	return (
		<button
			className={classNames(
				`flex-auto text-xs m-[5px] w-[25px] h-[25px]  border-t-none  rounded-full  shadow-md  bg-kupabluedark/[80%] text-white hover:shadow-md hover:bg-kupablue/60`,
				className
			)}
			onClick={functionCall}
		>
			<FontAwesomeIcon icon={faWrench} size="lg"></FontAwesomeIcon>
		</button>
	);
}

export function RoundButtonLock({ className }) {
	return (
		<div
			className={classNames(
				`flex text-xs m-[5px] rounded-full justify-center align-middle items-center w-[25px] h-[25px] max-w-[25px]    text-kupabluedark/[80%] `,
				className
			)}
		>
			<FontAwesomeIcon icon={faLock} size="lg"></FontAwesomeIcon>
		</div>
	);
}

export function RoundButtonMagnifyingGlass({ functionCall, className }) {
	return (
		<button
			className={classNames(
				`flex-auto text-xs m-[5px] w-[25px] h-[25px] max-w-[25px]  border-t-none rounded-full shadow-md  bg-kupabluedark/[80%] text-white hover:shadow-md hover:bg-kupablue/60`,
				className
			)}
			onClick={functionCall}
		>
			<FontAwesomeIcon
				icon={faMagnifyingGlass}
				size="lg"
			></FontAwesomeIcon>
		</button>
	);
}

export function RoundButtonHelp({ onClick }) {
	return (
		<button
			onClick={onClick}
			className="h-8 w-8 rounded-full shadow-md text-darkgray bg-white border-[1px] border-slate-300 cursor-pointer flex items-center justify-center hover:bg-gray"
		>
			<FontAwesomeIcon icon={faBook} size="sm"></FontAwesomeIcon>
		</button>
	);
}
