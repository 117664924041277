import { faRemove } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../../../../common/layouts/Global/CustomButton";

import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import FloatingLabelInput from "../../../../../common/layouts/Global/InputFloatingLabel";

function PriceBox({
	index,
	container,
	containers,
	handleChange,
	handleDelete,
	enabledPayment,
	containerMove,
}) {
	const { t } = useTranslation();
	return (
		<div
			className={"rounded-lg shadow-md w-full mb-3 p-1 relative"}
			style={{
				backgroundColor: "ghostwhite",
				display: "flex",
				flexDirection: "column",
				height: "100%",
				borderRadius: "10px",
			}}
		>
			<div>
				<div className="flex pt-9 p-2">
					<FloatingLabelInput
						className="inline w-10/12 "
						classNameInput={classNames("p-1 ", {
							"opacity-50": !enabledPayment,
						})}
						name={t("price")}
						value={container.price}
						onChange={(e) => handleChange(e, index, "price")}
						step="0.01"
						disabled={!enabledPayment}
						idInput={container.sizeML}
						inputMode="decimal"
					/>
					<span className="dark:text-kupawhite ml-2 pt-2">$</span>
				</div>

				<div className="flex p-2">
					<FloatingLabelInput
						className="inline w-10/12"
						classNameInput="p-1"
						name={t("settings.sizes.amount")}
						value={container.sizeML}
						onChange={(e) => handleChange(e, index, "sizeML")}
						step="5"
						idInput={container.sizeML}
						inputMode="decimal"
					/>
					<span className="dark:text-kupawhite ml-2 pt-2">ml</span>
				</div>
			</div>

			<CustomButton
				className="absolute top-0 right-0 flex justify-center transition-none bg-kupared/90 hover:bg-kupared/60 border-secondary  rounded-full border-2 h-9 w-9"
				icon={faRemove}
				type="delete"
				onClick={() => handleDelete(index)}
			/>
			<div className="absolute bottom-1 right-1">
				{index !== 0 && (
					<button
						className="text-kupablack dark:text-kupawhite mr-2 "
						onClick={() => containerMove(index, -1)}
					>
						<FontAwesomeIcon icon={faArrowLeft} />
					</button>
				)}
				{index !== containers.length - 1 && (
					<button
						className="text-kupablack dark:text-kupawhite "
						onClick={() => containerMove(index, 1)}
					>
						<FontAwesomeIcon icon={faArrowRight} />
					</button>
				)}
			</div>
		</div>
	);
}

export default PriceBox;
