export const findPreviousLevelIds = (data, targetId) => {
	const findInGroup = (group, parentIds = []) => {
		for (const item of group) {
			const currentIds = [...parentIds, item.id];

			if (item.id === targetId) {
				return currentIds;
			}

			if (item.group && item.group.length > 0) {
				const foundIds = findInGroup(item.group, currentIds);
				if (foundIds) {
					return foundIds;
				}
			}
		}
	};

	return findInGroup(data);
};

/* export const findMachineGroupIds = (data, targetMachineId) => {
	const result = [];

	const findInGroup = (group, parentIds = []) => {
		for (const item of group) {
			const currentIds = [...parentIds, item.id];

			if (item.machines && item.machines.length > 0) {
				for (const machine of item.machines) {
					if (machine.id === targetMachineId) {
						result.push(...parentIds, item.id);
					}
				}
			}

			if (item.group && item.group.length > 0) {
				findInGroup(item.group, currentIds);
			}
		}
	};

	findInGroup(data);
	return result;
};
 */
