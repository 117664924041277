import { useRef } from "react";

function CustomCheckbox({ checked, onChange, value }) {
	const inputRef = useRef(null);

	return (
		<div
			onClick={() => {
				inputRef.current.click();
			}}
			className="flex items-center cursor-pointer whitespace-nowrap"
		>
			<input
				ref={inputRef}
				type="checkbox"
				onChange={onChange}
				onClick={(e) => e.stopPropagation()}
				checked={checked}
			/>
			<span className="mx-2">{value}</span>
		</div>
	);
}

export default CustomCheckbox;
