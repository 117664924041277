import {
	faArchive,
	faChevronDown,
	faChevronRight,
	faClone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconGroup } from "../../../common/assets/icons/group.svg";
import { ReactComponent as IconMachine } from "../../../common/assets/icons/machine.svg";
import { ReactComponent as IconMachines } from "../../../common/assets/icons/machines.svg";
import { ReactComponent as IconSettings } from "../../../common/assets/icons/settings.svg";
import ordersLogo from "../../assets/icons/orders.png";
import { useSomePermission } from "../../context/hooks/usePermission";

const styleChildren = {
	display: "flex",
	alignItems: "center",
	paddingLeft: "10px",
};
const styleParent = {
	display: "flex",
	alignItems: "center",
	paddingLeft: "10px",
};
const selectedItem = {
	backgroundColor: "#cacaca",
	borderRadius: "5px",
	paddingLeft: "4px",
	paddingRight: "4px",
};

const openTrue = (isOpen, openTree) => {
	return (
		<div className="px-1 cursor-pointer">
			<div onClick={openTree}>
				<FontAwesomeIcon
					size="sm"
					icon={isOpen ? faChevronDown : faChevronRight}
				></FontAwesomeIcon>
			</div>
		</div>
	);
};
export const AccordionGroup = forwardRef(
	(
		{
			title,
			children,
			isOpen,
			action,
			openTree,
			collapse,
			parent,
			selected,
		},
		ref
	) => {
		const parentStyle = {
			display: "flex",
			alignItems: "center",
		};

		return (
			<div ref={ref}>
				<div style={parent ? parentStyle : styleParent}>
					<div
						style={{
							display: "flex",
							width: "100%",
							alignItems: "center",
						}}
					>
						{openTrue(isOpen, openTree)}
						<div className="w-5">
							<IconGroup width={20} height={20} />
						</div>
						<button
							onClick={action}
							style={{
								paddingLeft: "4px",
								overflow: "hidden",
							}}
						>
							<div
								style={{
									...(selected ? selectedItem : null),
									textOverflow: "ellipsis",
									overflow: "hidden",
									whiteSpace: "nowrap",
									width: "100%",
									fontSize: "14px",
								}}
							>
								{title}
							</div>
						</button>
					</div>
					<div>
						{parent && (
							<div onClick={collapse}>
								<FontAwesomeIcon
									size="sm"
									color={"#36454F"}
									icon={faClone}
								></FontAwesomeIcon>
							</div>
						)}
					</div>
				</div>

				{isOpen && (
					<div style={{ paddingLeft: "15px" }}>{children}</div>
				)}
			</div>
		);
	}
);

export const AccordionMachine = forwardRef(
	(
		{
			title,
			children,
			isOpen,
			action,
			lot,
			openTree,
			isEmpty,
			selected,
			childrenPermissions,
			group,
		},
		ref
	) => {
		const { t } = useTranslation();
		const hasChildrenPermissions = useSomePermission(
			childrenPermissions,
			group?.id
		);

		return (
			<div ref={ref}>
				<div style={styleChildren}>
					{(lot && isEmpty) || (!hasChildrenPermissions && lot) ? (
						<div className="ml-4" />
					) : (
						openTrue(isOpen, openTree)
					)}
					<div className="w-5">
						{lot ? (
							<IconMachine fill="blue" width={20} height={20} />
						) : (
							<IconMachines width={20} height={20} />
						)}
					</div>

					<button
						onClick={action}
						style={{
							paddingLeft: "4px",
							overflow: "hidden",
						}}
					>
						<div
							style={{
								...(selected ? selectedItem : null),
								textOverflow: "ellipsis",
								overflow: "hidden",
								whiteSpace: "nowrap",
								width: "100%",
								fontSize: "14px",
							}}
						>
							{t(title)}
						</div>
					</button>
				</div>
				{isOpen && (
					<div style={{ paddingLeft: "15px" }}>{children}</div>
				)}
			</div>
		);
	}
);

export const AccordionManagement = ({
	title,
	children,
	isOpen,
	action,
	selected,
}) => {
	const { t } = useTranslation();

	return (
		<div style={{ paddingLeft: "15px" }}>
			<div style={{ ...styleChildren, gap: 3 }}>
				<IconSettings width={20} height={20} />
				<button onClick={action}>
					<div
						style={{
							...(selected ? selectedItem : null),
							fontSize: "14px",
						}}
					>
						{t(title)}
					</div>
				</button>
			</div>
			{isOpen && <div>{children}</div>}
		</div>
	);
};

export const AccordionMachineSettings = forwardRef(
	({ title, children, isOpen, action, selected }, ref) => {
		const { t } = useTranslation();

		return (
			<div ref={ref}>
				<div style={{ ...styleChildren, paddingLeft: "25px", gap: 3 }}>
					<IconSettings width={20} height={20} />
					<button onClick={action}>
						<div
							style={{
								...(selected ? selectedItem : null),
								fontSize: "14px",
							}}
						>
							{t(title)}
						</div>
					</button>
				</div>
				{isOpen && <div>{children}</div>}
			</div>
		);
	}
);
export const AccordionCatalog = ({
	title,
	children,
	isOpen,
	action,
	selected,
}) => {
	const { t } = useTranslation();

	return (
		<div style={{ paddingLeft: "15px" }}>
			<div style={styleChildren}>
				{/* <img src={ordersLogo2} width={25} height={20} /> */}
				<img src={ordersLogo} width={21} height={18} />

				<button onClick={action}>
					<div
						style={{
							...(selected ? selectedItem : null),
							fontSize: "14px",
						}}
					>
						{t(title)}
					</div>
				</button>
			</div>
			{isOpen && <div>{children}</div>}
		</div>
	);
};
export const AccordionOrders = ({
	title,
	children,
	isOpen,
	action,
	selected,
}) => {
	const { t } = useTranslation();

	return (
		<div style={{ paddingLeft: "15px" }}>
			<div style={styleChildren}>
				<FontAwesomeIcon icon={faArchive} width={21} height={20} />

				<button onClick={action}>
					<div
						style={{
							...(selected ? selectedItem : null),
							fontSize: "14px",
						}}
					>
						{t(title)}
					</div>
				</button>
			</div>
			{isOpen && <div>{children}</div>}
		</div>
	);
};
