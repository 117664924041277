import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import CustomButton from "../../../../common/layouts/Global/CustomButton";
import Dropdown from "../../../../common/layouts/Global/Dropdown";
import FloatingLabelInput from "../../../../common/layouts/Global/InputFloatingLabel";
import PopupHeadTitle from "../../../../common/layouts/Global/PopupHeadTitle";
import { RoundButtonXMarkAbsolute } from "../../../../common/layouts/Global/RoundButton";
import { ToastNotSavedCustom } from "../../../../common/util/ToastUtil";
import { FetchRoles } from "../../../requests/dashboard/Roles";

function CreateMemberPopup({ open, closeMethod, createMethod, groupId }) {
	const { t } = useTranslation();
	const memberEmailInput = useRef(null);
	const [roles, setRoles] = useState([]);
	const [selectedRole, setSelectedRole] = useState(null);
	const { data } = useQuery([`group-${groupId}-roles`], {
		queryFn: () => FetchRoles(groupId),
	});
	useEffect(() => {
		const fetchRoles = async () => {
			const options = [];
			if (!data?.success) return;
			data?.data?.map((role) =>
				options.push({ value: role.id, label: role.name })
			);
			setRoles(options);
			setSelectedRole(null);
		};

		fetchRoles();
	}, [groupId, data]);

	const isValidEmail = (memberEmail) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(memberEmail);
	};

	return (
		<Popup
			open={open}
			onClose={closeMethod}
			contentStyle={{
				borderRadius: "0.75rem",
				width: "auto",
				padding: "10px",
			}}
			className="relative"
		>
			<RoundButtonXMarkAbsolute functionCall={closeMethod} />
			<PopupHeadTitle text={t("create.member.title")} />
			<div className="text-center mt-2 p-1">
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<FloatingLabelInput
						type="email"
						autoComplete="false"
						reference={memberEmailInput}
						name={t("create.member.member.email")}
					/>
					<Dropdown
						customTitle={t(`create.member.role.choice`)}
						list={roles.map((role) => ({
							title: role.label,
							onClick: () => setSelectedRole(role.value),
						}))}
					/>
				</div>
			</div>{" "}
			<div className="flex justify-end">
				<CustomButton
					className="w-40 mt-1 "
					text={t("add")}
					onClick={() => {
						const memberEmail = memberEmailInput.current.value;
						const memberRole = selectedRole;

						if (!isValidEmail(memberEmail)) {
							ToastNotSavedCustom(t("reset.inputError"));
						} else if (memberRole === null) {
							ToastNotSavedCustom(t("create.member.no.role"));
						} else {
							createMethod(memberEmail, memberRole);
						}
					}}
				/>
			</div>
		</Popup>
	);
}
export default CreateMemberPopup;
