import { requestAPI } from "../../../common/requests/RequestAPI";
import URL from "../../../common/requests/UrlsEnum";

export async function FetchMembers(groupId) {
	const members = await requestAPI(`${URL.GROUP}/${groupId}/user`, "GET");
	return members;
}

export async function DeleteMember(groupId, userId) {
	const data = await requestAPI(`${URL.GROUP}/${groupId}/user`, "DELETE", {
		user_id: userId,
	});
	return data;
}

export async function AddMember(groupId, email, roleId) {
	const data = await requestAPI(`${URL.GROUP}/${groupId}/user`, "POST", {
		email: email,
		role_id: roleId,
	});
	return data;
}

export async function UpdateMember(groupId, userId, newRoleId) {
	const data = await requestAPI(`${URL.GROUP}/${groupId}/user`, "PUT", {
		user_id: userId,
		role_id: newRoleId,
	});
	return data;
}
