import { faPlusCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import FilterPopup from "./FilterPopup";
import FilterState from "./FilterState";
import { useTranslation } from "react-i18next";

function Filter({
	filter,
	currentFilterData,
	currentEditingFilterData,
	handleChange,
	handleSave,
	handleDelete,
}) {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		document.addEventListener("click", handleDocumentClick);
		return () => document.removeEventListener("click", handleDocumentClick);
	}, []);

	useEffect(() => {
		if (!isOpen) handleSave();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const handleDocumentClick = (event) => {
		if (ref.current && ref.current.contains(event.target)) return;
		setIsOpen(false);
	};

	const isActive = Object.keys(currentFilterData).length > 0;

	return (
		<div className="m-1 relative" ref={ref}>
			<div
				className={classNames(
					"text-sm bg-backgroundgray border-2 border-kupablue rounded-full p-0.5 ",
					{
						"border-hidden": !isActive,
					}
				)}
			>
				<FontAwesomeIcon
					icon={
						Object.keys(currentFilterData).length > 0
							? faXmarkCircle
							: faPlusCircle
					}
					className="text-kupablue px-1 inline cursor-pointer"
					onClick={
						Object.keys(currentFilterData).length > 0
							? () => {
									setIsOpen(false);
									handleDelete();
							  }
							: () => setIsOpen(true)
					}
				/>
				<p
					className="inline cursor-pointer p-2"
					onClick={() => setIsOpen((prev) => !prev)}
				>
					{t(filter.name)}
					{isActive && (
						<FilterState
							filter={filter}
							currentFilterData={currentFilterData}
						/>
					)}
				</p>
			</div>

			{isOpen && (
				<FilterPopup
					filter={filter}
					currentEditingFilterData={currentEditingFilterData}
					handleChange={handleChange}
					handleClose={() => setIsOpen(false)}
				/>
			)}
		</div>
	);
}

export default Filter;
