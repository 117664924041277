import { requestAPI } from "../../../common/requests/RequestAPI";
import URL from "../../../common/requests/UrlsEnum";

export async function SaveMachine(machine) {
	const data = await requestAPI(
		URL.MACHINE_SETTINGS.replace("{id}", machine.machineId),
		"PUT",
		{
			payments: machine.payments,
			containers: machine.containers,
			recipes: machine.recipes,
			options: machine.options,
			valves: machine.valves,
		}
	);

	return data.success;
}
export async function FetchMachineSettings(machineId) {
	let data = await requestAPI(
		URL.MACHINE_SETTINGS.replace("{id}", machineId),
		"GET"
	);
	return data.data;
}

export async function FetchMachine(machineId) {
	let data = await requestAPI(URL.MACHINE.replace("{id}", machineId), "GET");
	return data.data;
}

export async function RenameMachine(machine, privateName) {
	let data = await requestAPI(
		URL.MACHINE_NAME.replace("{id}", machine.id),
		"PUT",
		{
			private_name: privateName,
		}
	);
	return data.data;
}
