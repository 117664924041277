import { useQuery } from "@tanstack/react-query";
import Filters from "../../../common/layouts/filters/core/Filters";
import { FetchRecipesAndOptions } from "../../../common/requests/machine/RecipesSupplements";
import {
	FetchVisibleDiscounts,
	FetchVisibleMachines,
} from "../../requests/machines/DrinkHistory";

function OrderFilters({ handleNewFilters, groupId, machineId }) {
	const { data: visibleMachineData } = useQuery(
		[`visibleMachines-${groupId}`],
		{
			queryFn: async () => await FetchVisibleMachines(groupId),
		}
	);

	const { data: visibleDiscountData } = useQuery(
		[`visibleDiscounts-${groupId}`],
		{
			queryFn: async () => await FetchVisibleDiscounts(groupId),
		}
	);

	const { data: recipeOptionData } = useQuery([`recipesAndOptions`], {
		queryFn: async () => await FetchRecipesAndOptions(),
	});

	let availableFilters = [
		{
			name: "recipes",
			type: "checkbox",
			sql: ["recipe_id"],
			values: recipeOptionData?.recipes,
		},
		{
			name: "options",
			type: "checkbox",
			sql: ["order_option", "some", "option_id"],
			values: recipeOptionData?.options,
			custom: [
				{
					sql: ["order_option", "none"],
					value: "no.options",
				},
			],
		},
		{
			name: "container.size.ml",
			type: "number",
			sql: ["container_size_ml"],
		},
		{
			name: "origin",
			type: "dropdown",
			sql: ["origin"],
			values: ["MACHINE", "APP"],
		},
		{
			name: "paid.price",
			type: "number",
			sql: ["paid_price"],
		},
		{
			name: "date",
			type: "date",
			sql: ["created_at"],
		},
		{
			name: "status",
			type: "checkbox",
			sql: ["status"],
			values: {
				PENDING: "PENDING",
				PROCESSING: "PROCESSING",
				COMPLETED: "COMPLETED",
			},
		},
		{
			name: "email",
			type: "string",
			sql: ["user", "email"],
		},
		{
			name: "discount",
			type: "checkbox",
			sql: ["order_discount", "some", "discount_id"],
			values: visibleDiscountData,
			custom: [
				{
					sql: ["order_discount", "none"],
					value: "no.discount",
				},
			],
		},
	];
	if (!machineId) {
		availableFilters = [
			{
				name: "stations",
				type: "checkbox",
				sql: ["machine_id"],
				values: visibleMachineData,
			},
			...availableFilters,
		];
	}

	return (
		<div>
			<Filters
				availableFilters={availableFilters}
				handleNewFilters={handleNewFilters}
			/>
		</div>
	);
}

export default OrderFilters;
