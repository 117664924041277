import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DimensionsContext } from "../../../common/context/DimensionsContext";
import FloatingLabelInput from "./InputFloatingLabel";
import CustomButton from "./CustomButton";

function DataPeriod() {
	const { t } = useTranslation();
	const { setEndDateAPI, setStartDateAPI } = useContext(DimensionsContext);
	const startRef = useRef(null);
	const endRef = useRef(null);
	const [startDateComponent, setStartDateComponent] = useState(
		moment().startOf("month").format("YYYY-MM-DD")
	);
	const [endDateComponent, setEndDateComponent] = useState(
		moment().format("YYYY-MM-DD")
	);
	const [submit, setSubmit] = useState(false);
	const applyChanges = () => {
		setStartDateComponent(startRef.current.value);

		setEndDateComponent(endRef.current.value);
		setSubmit(true);
	};
	const handleSubmit = () => {
		setSubmit(false);
		let dateValue = startRef.current.value;
		let dateValueEnd = endRef.current.value;

		if (dateValue === "" || dateValueEnd === "") return;

		let [yearStart, monthStart, dayStart] = dateValue
			.split("-")
			.map(Number);
		let localMidnightDateStart = new Date(
			yearStart,
			monthStart - 1,
			dayStart
		);

		setStartDateAPI(localMidnightDateStart.toISOString());
		let [yearEnd, monthEnd, dayEnd] = dateValueEnd.split("-").map(Number);
		let localMidnightDateEnd = new Date(yearEnd, monthEnd - 1, dayEnd);
		localMidnightDateEnd.setHours(23, 59, 59, 999);
		setEndDateAPI(localMidnightDateEnd.toISOString());
	};
	return (
		<div className=" mb-2 text-left ">
			<h1 className="pl-1 text-four font-bold text-lg ">{t("period")}</h1>

			<div className="inline-flex my-auto text-center">
				<FloatingLabelInput
					className={"w-full sm:w-56"}
					name={t("date.start")}
					defaultValue={startDateComponent}
					type="date"
					max={moment().format("YYYY-MM-DD")}
					onChange={applyChanges}
					reference={startRef}
				/>
				<FloatingLabelInput
					className={"w-full sm:w-56"}
					name={t("date.end")}
					defaultValue={endDateComponent}
					type="date"
					max={moment().format("YYYY-MM-DD")}
					onChange={applyChanges}
					reference={endRef}
				/>
				<CustomButton
					className="ml-2 my-auto px-5 "
					text={t("apply")}
					disabled={!submit}
					onClick={() => handleSubmit()}
				/>
			</div>
		</div>
	);
}
export default DataPeriod;
