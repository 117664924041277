import AddContainerBox from "./AddContainerBox";
import PriceBox from "./PriceBox";

function Containers({
	containers,
	enabledPayment,
	addContainer,
	handleDeleteContainer,
	handleChangeContainer,
	containerMove,
}) {
	return (
		<div className="grid lg:grid-cols-4 sm:grid-cols-2  gap-2 flex-wrap md:flex-nowrap">
			{containers.map((container, index) => (
				<PriceBox
					key={index}
					index={index}
					container={container}
					containers={containers}
					handleDelete={handleDeleteContainer}
					handleChange={handleChangeContainer}
					enabledPayment={enabledPayment}
					containerMove={containerMove}
				/>
			))}
			{containers.length < 4 && (
				<AddContainerBox addContainer={addContainer} />
			)}
		</div>
	);
}

export default Containers;
