import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import auth from "../../../common/config/firebase";

const loginWithGoogle = async () => {
	const provider = new GoogleAuthProvider();

	provider.addScope("profile");
	provider.addScope("email");

	await signInWithPopup(auth, provider);
};

export { loginWithGoogle };
