import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import CustomButton from "../../../../common/layouts/Global/CustomButton";
import FloatingLabelInput from "../../../../common/layouts/Global/InputFloatingLabel";
import PopupHeadTitle from "../../../../common/layouts/Global/PopupHeadTitle";
import {
	RoundButtonXMark,
	RoundButtonXMarkAbsolute,
} from "../../../../common/layouts/Global/RoundButton";
import { ToastNotSavedCustom } from "../../../../common/util/ToastUtil";
import { FetchMachineGroup } from "../../../requests/machines/FetchAllMachines";
import { MachineFilter } from "../MachineFilter";
import { UsersFilter } from "../UsersFilter";

const getCurrentDateHour = (currentDate) => {
	if (!currentDate) return;
	const yyyy = currentDate.getFullYear();
	const mm = String(currentDate.getMonth() + 1).padStart(2, "0");
	const dd = String(currentDate.getDate()).padStart(2, "0");
	const hh = String(currentDate.getHours()).padStart(2, "0");
	const min = String(currentDate.getMinutes()).padStart(2, "0");
	return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
};
function ModifyDiscountPopup({
	open,
	closeMethod,
	modifyMethod,
	currentDiscount,
	groupId,
}) {
	const { t } = useTranslation();
	const discountNameInput = useRef(null);
	const discountCodeInput = useRef(null);
	const discountStartDateInput = useRef(null);
	const discountEndDateInput = useRef(null);
	const [isApplicationChecked, setIsApplicationChecked] = useState(false);
	const [isMachineChecked, setIsMachineChecked] = useState(false);

	const [users, setUsers] = useState([]);
	const [newId, setnewId] = useState(1);
	const [machines, setMachines] = useState([]);
	const [selectedIds, setSelectedIds] = useState([]);
	useEffect(() => {
		setIsApplicationChecked(currentDiscount.available_app);
		setIsMachineChecked(currentDiscount.available_machine);
		const initialUsers =
			currentDiscount?.discount_use?.map((discountUse, index) => {
				return { ...discountUse, newId: index + 1 };
			}) || [];
		const initialMachines = currentDiscount?.discount_machine?.map(
			(machine) => {
				return machine?.machine?.machine_name?.machine_id;
			}
		);
		setSelectedIds(initialMachines);
		setnewId(initialUsers.length + 1);
		setUsers(initialUsers);
	}, [
		open,
		currentDiscount.available_app,
		currentDiscount.available_machine,
		currentDiscount?.discount_machine,
		currentDiscount?.discount_use,
	]);

	useEffect(() => {
		const fetchMachines = async () => {
			const fetchMachines = await FetchMachineGroup(groupId);
			const options = [];
			fetchMachines?.map((machine) =>
				options.push({
					id: machine.id,
					name: machine?.machine_name?.private_name,
				})
			);
			setMachines(options);
			setSelectedIds(["all"]);
		};

		fetchMachines();
	}, [groupId]);
	if (!currentDiscount) return null;

	const newUser = {
		newId: 0,
		user: null,
		initial_uses: 0,
		uses_left: 0,
		unlimited: 1,
	};
	const handleUserUpdate = (userId, field, newValue) => {
		if (field === "email") {
			setUsers((prevUsers) =>
				prevUsers.map((user) =>
					user.newId === userId
						? { ...user, user: { email: newValue } }
						: user
				)
			);
			return;
		}
		setUsers((prevUsers) =>
			prevUsers.map((user) =>
				user.newId === userId ? { ...user, [field]: newValue } : user
			)
		);
	};
	const handleDeleteUser = (userList) => {
		setUsers((prevUsers) => {
			let updatedUsers = [];
			if (userList.id) {
				updatedUsers = prevUsers?.map((user) => {
					if (user.id === userList.id) {
						return { ...user, delete: true };
					}
					return user;
				});
			} else {
				updatedUsers = prevUsers?.filter(
					(user) => user.newId !== userList.newId
				);
			}
			const remainingUsers = updatedUsers.filter((user) => !user.delete);
			if (remainingUsers.length === 0) {
				return [...updatedUsers, { ...newUser, new: true }];
			}

			return [...updatedUsers];
		});
	};
	const handleAddUser = () => {
		setnewId((prev) => prev + 1);
		const deletedUserIndex = users.findIndex(
			(user) =>
				user.user === null &&
				(user.delete === undefined || user.delete === false)
		);
		if (deletedUserIndex !== -1) {
			if (users[deletedUserIndex].id) {
				setUsers((prevUsers) => {
					const updatedUsers = [...prevUsers];
					updatedUsers[deletedUserIndex] = {
						...updatedUsers[deletedUserIndex],
						delete: true,
					};
					return [
						...updatedUsers,
						{
							...newUser,
							newId,
							new: true,
							user: {
								email: "",
							},
							uses_left: 0,
						},
					];
				});
			} else {
				setUsers((prevUsers) => {
					const filteredUsers = prevUsers.filter(
						(_, index) => index !== deletedUserIndex
					);

					return [
						...filteredUsers,
						{
							...newUser,
							newId,
							new: true,
							user: {
								email: "",
							},
							uses_left: 0,
						},
					];
				});
			}
		} else {
			setUsers((prevUsers) => [
				...prevUsers,
				{
					...newUser,
					newId,
					new: true,
					user: {
						email: "",
					},
					uses_left: 0,
				},
			]);
		}
	};
	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};
	const handleSubmit = () => {
		const discountName = discountNameInput.current.value;
		const discountCode =
			discountCodeInput.current.value === ""
				? null
				: discountCodeInput.current.value;
		let discountStartDate = discountStartDateInput.current.value
			? new Date(discountStartDateInput.current.value)
			: null;
		let discountEndDate = discountEndDateInput.current.value
			? new Date(discountEndDateInput.current.value)
			: null;

		const isEmpty = (value, message) => {
			if (value === "") {
				ToastNotSavedCustom(t(message));
				return true;
			}
			return false;
		};

		const isInvalidCode = (code) => {
			if (!/^[a-zA-Z0-9]+$/.test(code)) {
				ToastNotSavedCustom(t("discount.code.invalid"));
				return true;
			}
			return false;
		};

		if (isEmpty(discountName, t("discount.name.invalid"))) return;

		if (
			!discountCode &&
			!users.every(
				(user) => user.unlimited === 1 || user.unlimited === true
			)
		) {
			ToastNotSavedCustom(t("discount.code.unlimited"));
			return;
		}
		if (
			discountCode?.toString().length < 4 &&
			discountCode?.toString().length > 0
		) {
			ToastNotSavedCustom(t("discount.code.invalid.length"));
			return;
		}
		if (discountCode?.toString().length >= 4 && isInvalidCode(discountCode))
			return;

		if (isEmpty(discountStartDate, t("discount.start.date.invalid")))
			return;

		if (
			discountEndDate &&
			!moment(discountStartDate).isBefore(moment(discountEndDate))
		) {
			ToastNotSavedCustom(t("discount.date.diff.invalid"));
			return;
		}
		const nullUsers = users.filter((user) => user.email === null);
		const invalidEmailUsers = users.filter(
			(user) => user?.user !== null && !isValidEmail(user?.user?.email)
		);
		if (nullUsers.length && users.length > 1) {
			ToastNotSavedCustom(t("discount.email.null"));
			return;
		}
		if (invalidEmailUsers.length) {
			ToastNotSavedCustom(t("discount.email.invalid"));
			return;
		}
		const invalidUseUsers = users.filter(
			(user) =>
				user.new &&
				!user.unlimited &&
				(user.initial_uses === 0 || user.initial_uses < user.uses_left)
		);
		if (invalidUseUsers.length) {
			ToastNotSavedCustom(t("discount.initial.zero"));
			return;
		}
		const invalidUseNumberUsers = users.filter(
			(user) => !user.delete && isNaN(user.initial_uses)
		);
		if (invalidUseNumberUsers.length) {
			ToastNotSavedCustom(t("discount.initial.notNumber"));
			return;
		}
		discountStartDate = discountStartDate.toISOString();
		if (discountEndDate) {
			discountEndDate = discountEndDate.toISOString();
		} else {
			discountEndDate = null;
		}
		modifyMethod(
			discountName,
			discountCode,
			discountStartDate,
			discountEndDate,
			isMachineChecked,
			isApplicationChecked,
			selectedIds,
			users
		);
	};
	const rows = [
		...users
			?.filter((user) => !user.delete)
			?.map((User) => {
				return [
					User?.user === null ? (
						t("all.users")
					) : User.new ? (
						<FloatingLabelInput
							className={"w-[100px]"}
							value={User?.user?.email}
							onChange={(e) =>
								handleUserUpdate(
									User.newId,
									"email",
									e.target.value
								)
							}
						/>
					) : (
						User?.user?.email
					),
					!User.new ? (
						User.unlimited ? (
							"-"
						) : (
							`${User.uses_left}/${User.initial_uses}`
						)
					) : (
						<FloatingLabelInput
							className={"w-[100px]"}
							value={User.initial_uses}
							disabled={User.unlimited}
							type="number"
							min={0}
							onChange={(e) =>
								handleUserUpdate(
									User.newId,
									"initial_uses",
									parseInt(e.target.value)
								)
							}
						/>
					),
					<input
						type="checkbox"
						checked={User?.unlimited}
						onChange={(e) =>
							handleUserUpdate(
								User.newId,
								"unlimited",
								e.target.checked ? 1 : 0
							)
						}
					/>,
					User?.user !== null && (
						<RoundButtonXMark
							functionCall={() => handleDeleteUser(User)}
						/>
					),
				];
			}),
		[<CustomButton text={t("add")} onClick={handleAddUser} />],
	];
	return (
		<Popup
			open={open}
			onClose={closeMethod}
			contentStyle={{
				borderRadius: "0.75rem",
				//width: "auto",
				padding: "10px",
				maxHeight: "95%",
				width: "90%",
				maxWidth: "600px",
			}}
			//className="relative"
		>
			<RoundButtonXMarkAbsolute functionCall={closeMethod} />

			<PopupHeadTitle text={t("modify.discount.title")} />
			<div className="text-center mt-2 p-1">
				<div
					style={{
						overflow: "auto",
						//marginTop: "10px",
						//display: "flex",
						//flexDirection: "column",
						alignItems: "center",
					}}
				>
					<div className="w-5/6">
						<div className="block sm:flex gap-2 sm:my-2 w-full">
							<FloatingLabelInput
								className={"w-full"}
								reference={discountNameInput}
								name={t("name")}
								defaultValue={currentDiscount.description}
							/>

							<FloatingLabelInput
								className={"w-full"}
								reference={discountCodeInput}
								name={
									t("create.discount.code") +
									" " +
									t("optional")
								}
								defaultValue={currentDiscount.discount_code}
							/>
						</div>
						<div className="sm:flex xs:block  ">
							<FloatingLabelInput
								className={"w-full"}
								reference={discountStartDateInput}
								min={getCurrentDateHour(new Date())}
								type="datetime-local"
								defaultValue={getCurrentDateHour(
									new Date(currentDiscount.start_date)
								)}
								name={t("date.start")}
							/>
							<FloatingLabelInput
								className={"w-full"}
								reference={discountEndDateInput}
								min={getCurrentDateHour(new Date())}
								type="datetime-local"
								defaultValue={getCurrentDateHour(
									currentDiscount?.end_date === null
										? null
										: new Date(currentDiscount.end_date)
								)}
								name={t("date.end") + " " + t("optional")}
							/>
						</div>
					</div>

					<div className="mt-2 text-left">
						{t("discount.activate")} :
					</div>
					<div className="flex  gap-2 my-2">
						<div
							className={` cursor-pointer rounded-sm ${
								isApplicationChecked
									? "bg-kupablue hover:bg-kupablue/80 text-white p-2 drop-shadow-[0_5px_5px_rgba(0,0,0,0.1)]"
									: "bg-white text-kupablue p-2 ring-1 ring-kupablue hover:bg-kupablue/10 "
							}`}
							onClick={() =>
								setIsApplicationChecked((prev) => !prev)
							}
						>
							{t("APP")}
						</div>
						<div
							className={` cursor-pointer rounded-sm ${
								isMachineChecked
									? "bg-kupablue hover:bg-kupablue/80 text-white p-2 drop-shadow-[0_5px_5px_rgba(0,0,0,0.1)]"
									: "bg-white text-kupablue p-2 ring-1 ring-kupablue hover:bg-kupablue/10 "
							}`}
							onClick={() => setIsMachineChecked((prev) => !prev)}
						>
							{t("station")}
						</div>
					</div>
					<div className="w-full sm:w-auto pl-4 sm:p-0 align-left text-left">
						<MachineFilter
							machineIds={machines}
							selectedIds={selectedIds}
							setSelectedIds={setSelectedIds}
						/>
						<UsersFilter
							rows={rows}
							columns={[
								t("email"),
								t("discounts.initial_uses"),
								t("discounts.unlimited"),
								t("actions"),
							]}
							users={users}
							firstUser={users[0]?.user}
						/>
					</div>
				</div>
				<div className="flex justify-end">
					<CustomButton
						className="w-40"
						text={t("modify")}
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</Popup>
	);
}

export default ModifyDiscountPopup;
