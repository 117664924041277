import classNames from "classnames";
import React, { useRef } from "react";
import WhiteBlock from "./WhiteBlock";
const WhiteBlockDivWihtIcon = ({
	children,
	text,
	icon,
	iconClick,
	className,
	classNameText,
	color,
	classNameChild,
}) => {
	const containerWB = useRef(null);

	return (
		<WhiteBlock
			className={"flex-row justify-between overflow-hidden"}
			flexRow={true}
		>
			<div
				className={`w-[30%]  h-auto flex align-middle ${
					iconClick && "cursor-pointer"
				}  `}
				style={{ backgroundColor: color }}
				onClick={iconClick}
			>
				<div className="m-auto w-[40px] h-[40px] md:w-[50px] md:h-[50px]">
					{icon}
				</div>
			</div>
			<div>
				{text && (
					<div className="flex flex-row align-middle p-[15px] py-[5px] justify-end">
						<h1
							className="text-four font-bold"
							style={{
								fontSize: "16px",
							}}
						>
							{text}
						</h1>
					</div>
				)}
				<div
					ref={containerWB}
					className={classNames(
						"flex p-[10px] md:p-[15px] pt-0",
						classNameChild
					)}
				>
					{children}
				</div>
			</div>
		</WhiteBlock>
	);
};
export default WhiteBlockDivWihtIcon;
