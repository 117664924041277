import { useQuery } from "@tanstack/react-query";
import Filters from "../../../common/layouts/filters/core/Filters";
import { FetchRecipesAndOptions } from "../../../common/requests/machine/RecipesSupplements";

function StoreOrderFilters({ handleNewFilters, groupId }) {
	const { data: recipeOptionData, isLoading } = useQuery(
		[`recipesAndOptions`],
		{
			queryFn: async () => await FetchRecipesAndOptions(),
		}
	);
	if (isLoading) return null;
	let availableFilters = [
		{
			name: "product",
			type: "checkbox",
			sql: ["productorder_detail", "some", "product_id"],
			values: {
				...recipeOptionData?.flavors,
				...recipeOptionData?.options,
			},
		},
		{
			name: "ref.id",
			type: "string",
			sql: ["ref_id"],
		},
		{
			name: "date",
			type: "date",
			sql: ["created_at"],
		},
		{
			name: "status",
			type: "checkbox",
			sql: ["status"],
			values: {
				PENDING: "PENDING",
				PROCESSING: "PROCESSING",
				IN_TRANSIT: "IN_TRANSIT",
				DELIVERED: "DELIVERED",
				CANCELED: "CANCELED",
			},
		},
		{
			name: "email",
			type: "string",
			sql: ["user", "email"],
		},
	];
	return (
		<div>
			<Filters
				availableFilters={availableFilters}
				handleNewFilters={handleNewFilters}
			/>
		</div>
	);
}

export default StoreOrderFilters;
