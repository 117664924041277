import i18next from "i18next";

function CheckContainers(containers) {
	if (containers.length === 0) {
		return {
			success: false,
			info: i18next.t("settings.error.no.conatiners"),
		};
	}
	let duplicateTest = [];
	for (let i = 0; i < containers.length; i++) {
		if (containers[i].sizeML < 50 || containers[i].price > 1000) {
			return {
				success: false,
				info: i18next.t("settings.error.negative.null.conatiners"),
			};
		}
		if (containers[i].sizeML % 5 !== 0) {
			return {
				success: false,
				info: i18next.t("settings.error.multiple.5"),
			};
		}
		duplicateTest.push(containers[i].sizeML);
	}
	if (new Set(duplicateTest).size !== duplicateTest.length) {
		return {
			success: false,
			info: i18next.t("settings.error.duplicates.containers"),
		};
	}
	return { success: true, info: "Nothing to report." };
}

function CheckValves(valves) {
	for (let i = 0; i < valves.length; i++) {
		if (valves[i].value <= 0) {
			return {
				success: false,
				info: i18next.t("settings.error.negative.null.valves"),
			};
		}
	}

	return { success: true, info: "Nothing to report." };
}

export { CheckContainers, CheckValves };
