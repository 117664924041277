import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-chained-backend";
import HttpApi from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";

import URL from "../../requests/UrlsEnum";

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.use({
		type: "postProcessor",
		name: "new_touche",
		process(value, key, options, translator) {
			if (key[0] === "touche") return i18n.t("new_touche");

			return value;
		},
	})
	.init({
		load: "languageOnly",
		fallbackLng: "en",
		backend: {
			backends: [LocalStorageBackend, HttpApi],
			backendOptions: [
				{
					expirationTime: 60 * 1000, // 1 minute
				},
				{
					loadPath: URL.TRANSLATION,
				},
			],
		},
		postProcess: ["new_touche"],
	});

export default i18n;
