import WhiteBlockDivWihtIcon from "../../../common/layouts/Global/WhiteBlockDivWihtIcon";

function DataWithIconAndNumber({ color, icon, iconClick, text, reload, data }) {
	return (
		<WhiteBlockDivWihtIcon
			color={color}
			icon={icon}
			iconClick={iconClick}
			text={text}
			classNameChild={"flex justify-end"}
		>
			<div
				className={` flex content-end flex-wrap justify-end text-end text-[20px] md:text-[30px] text-darkbluegray ${
					reload ? "blur-[2px]" : undefined
				}`}
			>
				{data}
			</div>
		</WhiteBlockDivWihtIcon>
	);
}

export default DataWithIconAndNumber;
