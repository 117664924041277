import {
	faLanguage,
	faChevronRight,
	faChevronLeft,
	faChevronUp,
	faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Arrow({ color, size, orientation, action }) {
	let icon;
	switch (orientation) {
		case "up":
			icon = faChevronUp;
			break;
		case "left":
			icon = faChevronLeft;
			break;
		case "right":
			icon = faChevronRight;
			break;
		case "down":
			icon = faChevronDown;
			break;
		default:
	}

	return (
		<div className="">
			<FontAwesomeIcon
				icon={icon}
				size={size}
				color={color}
				onClick={action}
			/>
		</div>
	);
}
export default Arrow;
