import { useTranslation } from "react-i18next";
import LogTable from "../../layouts/tables/LogTable";

function Logs() {
	const { t } = useTranslation();
	document.title = `KUPA Station | ${t("logs")}`;

	return (
		<div>
			<LogTable />
		</div>
	);
}

export default Logs;
