import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomSelectOption from "../../Global/CustomSelectOption";
import FloatingLabelInput from "../../Global/InputFloatingLabel";

function FilterTypeNumber({ currentEditingFilterData, handleChange }) {
	const { t } = useTranslation();

	const operators = [
		{
			name: "filter.equal",
			operator: "=",
		},
		{
			name: "filter.greater",
			operator: ">",
		},
		{
			name: "filter.less",
			operator: "<",
		},
		{
			name: "filter.between",
			operator: "between",
		},
	];

	useEffect(() => {
		if (!currentEditingFilterData.operator)
			handleChange({
				...currentEditingFilterData,
				operator: operators[0].operator,
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<CustomSelectOption
				value={
					operators.find(
						(o) => o.operator === currentEditingFilterData.operator
					)?.name || ""
				}
				onChange={(e) =>
					handleChange({
						operator: operators.find(
							(o) => o.name === e.target.value
						).operator,
					})
				}
				values={operators.map((o) => o.name)}
			/>

			{currentEditingFilterData.operator === "between" ? (
				<div>
					<FloatingLabelInput
						type="number"
						classNameInput="px-2 py-1"
						className="mt-2 w-auto"
						value={currentEditingFilterData.firstValue || ""}
						placeholder={0}
						onChange={(e) =>
							handleChange({
								...currentEditingFilterData,
								firstValue: e.target.value,
							})
						}
					/>
					<p className="inline mx-1">{t("and")}</p>
					<FloatingLabelInput
						type="number"
						classNameInput="px-2 py-1"
						className={"w-auto"}
						value={currentEditingFilterData.secondValue || ""}
						placeholder={0}
						onChange={(e) =>
							handleChange({
								...currentEditingFilterData,
								secondValue: e.target.value,
							})
						}
					/>
				</div>
			) : (
				<FloatingLabelInput
					type="number"
					classNameInput="px-2 py-1"
					className="mt-2 w-auto"
					value={currentEditingFilterData.firstValue || ""}
					placeholder={0}
					onChange={(e) =>
						handleChange({
							...currentEditingFilterData,
							firstValue: e.target.value,
						})
					}
				/>
			)}
		</div>
	);
}

export default FilterTypeNumber;
