import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { findPreviousLevelIds } from "./functions";

import { useContext } from "react";
import Permissions from "../../../common/config/permissions";
import { useAuth } from "../../../common/context/AuthContext";
import { TreeContext } from "../../../common/context/TreeContext";
import Restricted from "../../../common/context/hooks/Restricted";
import {
	AccordionGroup,
	AccordionMachine,
	AccordionMachineSettings,
	AccordionManagement,
} from "../../../common/layouts/Tree/AccordionTree";

const GroupTree = ({
	data,
	group,
	opengroups,
	setOpenGroups,
	machinesongroup,
	setMachineOnGroups,
	machinesonmachine,
	setMachinesOnMachine,
	parent,
	setLastSelected,
	lastSelected,
	handleClickInsideModal = () => {
		return;
	},
	openModal,
}) => {
	const navigate = useNavigate();
	const selectedElementRef = useRef();
	const AddToListOrDeleteIfExists = (setFunction, list, idToCheck) => {
		const isGroupIncluded = list.includes(idToCheck);
		setFunction(
			isGroupIncluded
				? list.filter((el) => el !== idToCheck)
				: [...list, idToCheck]
		);
	};
	const { hasGlobalPermission } = useAuth();

	const renderGroups = (groupData) => {
		if (!groupData || groupData.length === 0) {
			return null;
		}
		return (
			<div style={{ direction: "ltr" }}>
				{groupData?.map((group, index) => {
					const isSelected =
						lastSelected.type === "group" &&
						lastSelected.id === group.id;

					return (
						<AccordionGroup
							title={group.name}
							isOpen={
								parent ? true : opengroups?.includes(group.id)
							}
							parent={parent}
							selected={
								lastSelected.type === "group" &&
								lastSelected.id === group.id
							}
							collapse={
								parent &&
								(() => {
									setOpenGroups([]);
									setMachineOnGroups([]);
									setMachinesOnMachine([]);
								})
							}
							openTree={() =>
								AddToListOrDeleteIfExists(
									setOpenGroups,
									opengroups,
									group.id
								)
							}
							action={() => {
								navigate(`/portal/${group.id}`);
								setLastSelected({
									type: "group",
									id: group.id,
								});
								handleClickInsideModal();
							}}
							key={index}
							ref={(el) => {
								if (isSelected) selectedElementRef.current = el;
							}}
						>
							<Restricted
								to={[
									Permissions.GROUP_MEMBER_VIEW,
									Permissions.GROUP_ROLE_VIEW,
									Permissions.GROUP_DISCOUNT_VIEW,
									Permissions.GROUP_VIDEO_VIEW,
								]}
								groupId={group.id}
							>
								<AccordionManagement
									title="management"
									selected={
										lastSelected.type === "Management" &&
										lastSelected.id === group.id
									}
									action={() => {
										navigate(
											`/portal/${group.id}/management`
										);
										setLastSelected({
											type: "Management",
											id: group.id,
										});
										handleClickInsideModal();
									}}
								/>
							</Restricted>

							{/* ##### Machines #####  */}
							<Restricted
								to={Permissions.GROUP_MACHINES_VIEW}
								groupId={group.id}
							>
								<AccordionMachine
									title="stations"
									isEmpty={group.machines.length > 0}
									lot={false}
									isOpen={machinesongroup.includes(group.id)}
									selected={
										lastSelected.type === "Stations" &&
										lastSelected.id === group.id
									}
									openTree={() =>
										AddToListOrDeleteIfExists(
											setMachineOnGroups,
											machinesongroup,
											group.id
										)
									}
									action={() => {
										navigate(
											`/portal/${group.id}/machines`
										);
										setLastSelected({
											type: "Stations",
											id: group.id,
										});

										handleClickInsideModal();
									}}
								>
									{group.machines.map((machine) => {
										const isSelected =
											lastSelected.type === "station" &&
											lastSelected.id === machine.id;

										return (
											<AccordionMachine
												lot={true}
												title={
													hasGlobalPermission(
														Permissions.GROUP_MACHINES_VIEW
													)
														? machine.factory_name
														: machine.private_name
												}
												isOpen={machinesonmachine.includes(
													machine.id
												)}
												selected={
													lastSelected.type ===
														"station" &&
													lastSelected.id ===
														machine.id
												}
												key={machine.id}
												openTree={() =>
													AddToListOrDeleteIfExists(
														setMachinesOnMachine,
														machinesonmachine,
														machine.id
													)
												}
												action={() => {
													navigate(
														`/portal/${group.id}/machine/${machine.id}/`
													);
													setLastSelected({
														type: "station",
														id: machine.id,
													});
													handleClickInsideModal();
												}}
												childrenPermissions={[
													Permissions.MACHINE_SETTING_CONTAINER_EDIT,
													Permissions.MACHINE_SETTING_PAYMENT_TOGGLE,
													Permissions.MACHINE_SETTING_RECIPE_EDIT,
													Permissions.MACHINE_SETTING_RECIPE_TOGGLE,
													Permissions.MACHINE_SETTING_OPTION_EDIT,
													Permissions.MACHINE_SETTING_OPTION_TOGGLE,
													Permissions.MACHINE_SETTING_VALVE_EDIT,
												]}
												group={group}
												ref={(el) => {
													if (isSelected)
														selectedElementRef.current =
															el;
												}}
											>
												<Restricted
													to={[
														Permissions.MACHINE_SETTING_CONTAINER_EDIT,
														Permissions.MACHINE_SETTING_PAYMENT_TOGGLE,
														Permissions.MACHINE_SETTING_RECIPE_EDIT,
														Permissions.MACHINE_SETTING_RECIPE_TOGGLE,
														Permissions.MACHINE_SETTING_OPTION_EDIT,
														Permissions.MACHINE_SETTING_OPTION_TOGGLE,
														Permissions.MACHINE_SETTING_VALVE_EDIT,
													]}
													groupId={group.id}
												>
													<AccordionMachineSettings
														title="settings"
														isOpen={true}
														action={() => {
															navigate(
																`/portal/${group.id}/machine/${machine.id}/setting`
															);
															setLastSelected({
																type: "Setting",
																id: machine.id,
															});
															handleClickInsideModal();
														}}
														selected={
															lastSelected.type ===
																"Setting" &&
															lastSelected.id ===
																machine.id
														}
														ref={(el) => {
															if (
																lastSelected.type ===
																	"Setting" &&
																lastSelected.id ===
																	machine.id
															)
																selectedElementRef.current =
																	el;
														}}
													/>
												</Restricted>
											</AccordionMachine>
										);
									})}
								</AccordionMachine>
							</Restricted>

							{/* ##### Groups #####  */}
							{group.group && group.group.length > 0 && (
								<GroupTree
									data={data}
									parent={false}
									group={group.group}
									opengroups={opengroups}
									setOpenGroups={setOpenGroups}
									machinesongroup={machinesongroup}
									setMachineOnGroups={setMachineOnGroups}
									machinesonmachine={machinesonmachine}
									setMachinesOnMachine={setMachinesOnMachine}
									lastSelected={lastSelected}
									setLastSelected={setLastSelected}
									handleClickInsideModal={
										handleClickInsideModal
									}
								/>
							)}
						</AccordionGroup>
					);
				})}
			</div>
		);
	};
	useEffect(() => {
		if (selectedElementRef.current) {
			selectedElementRef.current.scrollIntoView({
				behavior: "smooth",
			});
		}
	}, [lastSelected, machinesongroup, machinesonmachine, openModal]);

	return <div>{renderGroups(group)}</div>;
};
function GroupTag({ data, handleClickInsideModal, openModal }) {
	const [opengroups, setOpenGroups] = useState([]);
	const [machinesongroup, setMachineOnGroups] = useState([]);
	const [machinesonmachine, setMachinesOnMachine] = useState([]);
	const [lastSelectedGroup, setLastSelectedGroup] = useState({
		type: "",
		id: 0,
	});

	const location = useLocation();
	const { updateTree, lastSelected, setLastSelected } =
		useContext(TreeContext);
	useEffect(() => {
		const GetPathIfListAreEmpty = () => {
			const AllArgs = location.pathname.split("/");
			let groupid = parseInt(AllArgs[2]);
			let pathName = AllArgs[3];
			let machineid = parseInt(AllArgs[4]);
			const pathtree = findPreviousLevelIds(data, groupid);
			if (typeof pathName === "string" && pathName.includes("machine")) {
				if (machineid) {
					machinesonmachine.includes(machineid) ||
						setMachinesOnMachine([...machinesonmachine, machineid]);
					if (location.pathname.includes("setting")) {
						setLastSelected({
							type: "Setting",
							id: machineid,
						});
					} else {
						setLastSelected({
							type: "station",
							id: machineid,
						});
					}
				} else {
					setLastSelected({
						type: "Stations",
						id: groupid,
					});
				}
				setMachineOnGroups([...machinesongroup, groupid]);
				setOpenGroups([...new Set([...opengroups, ...pathtree])]);
			} else {
				if (location.pathname.includes("management")) {
					setLastSelected({
						type: "Management",
						id: groupid,
					});
				} else {
					setLastSelected({ type: "group", id: groupid });
				}
			}
			if (groupid > 0) {
				if (opengroups?.length === 0) {
					setOpenGroups(pathtree);
				}
			}
		};

		GetPathIfListAreEmpty();
	}, [updateTree]);

	return (
		<div
			style={{
				width: "100%",
				padding: "5px",
				overflowX: "hidden",
				height: "75vh",
				overflowY: "auto",
				direction: "rtl",
			}}
		>
			<GroupTree
				data={data}
				parent={true}
				group={data}
				opengroups={opengroups}
				setOpenGroups={setOpenGroups}
				machinesongroup={machinesongroup}
				setMachineOnGroups={setMachineOnGroups}
				machinesonmachine={machinesonmachine}
				setMachinesOnMachine={setMachinesOnMachine}
				lastSelected={lastSelected}
				setLastSelected={setLastSelected}
				lastSelectedGroup={lastSelectedGroup}
				setLastSelectedGroup={setLastSelectedGroup}
				handleClickInsideModal={handleClickInsideModal}
				openModal={openModal}
			/>
		</div>
	);
}

export default GroupTag;
