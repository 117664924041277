import { requestAPI } from "../../common/requests/RequestAPI";
import URL from "../../common/requests/UrlsEnum";
let abortController;
export async function FetchGroupOrders(
	groupId,
	filters = undefined,
	page = 1,
	pageCount = 40
) {
	try {
		if (abortController) abortController.abort();
		filters = filters ?? {};
		abortController = new AbortController();
		const data = await requestAPI(
			`${URL.STORE_ORDER_SEARCH}?page=${page}&countPerPage=${pageCount}&groupId=${groupId}`,
			"POST",
			filters,
			abortController.signal
		);
		return data;
	} catch (error) {
		return [];
	}
}
export async function FetchOrder(orderId) {
	const data = await requestAPI(
		URL.STORE_ORDER_ID.replace("{id}", orderId),
		"GET"
	);
	return data;
}

export async function AddOrder(counts, text, groupId) {
	const items = Object.keys(counts)
		.filter((key) => counts[key] > 0)
		.map((key) => ({ id: parseInt(key), quantity: counts[key] }));
	if (items.length === 0) return false;

	const data = await requestAPI(URL.STORE_ORDER, "POST", {
		client_group_id: parseInt(groupId),
		user_comment: text,
		items,
	});
	return data;
}
export async function DeleteOrder(orderId, deleteComment) {
	const data = await updateOrderStatus(orderId, "CANCELED", deleteComment);

	return data;
}
export async function AcceptOrder(order, status) {
	if (order.status === "CANCELED")
		return { success: false, data: { message: "Already canceled" } };

	if (status) {
		const updatedOrder = await updateOrderStatus(
			order.id,
			status.toUpperCase()
		);
		return updatedOrder;
	}
	let newStatus;

	switch (order.status) {
		case "PENDING":
			newStatus = "PROCESSING";
			break;
		case "PROCESSING":
			newStatus = "IN_TRANSIT";
			break;
		case "IN_TRANSIT":
			newStatus = "DELIVERED";
			break;
		default:
			return { success: false, data: { message: "Already delivered" } };
	}

	const updatedOrder = await updateOrderStatus(order.id, newStatus);
	return updatedOrder;
}

async function updateOrderStatus(orderId, newStatus, delete_comment) {
	if (newStatus === "CANCELED" && !delete_comment) {
		throw new Error("delete_comment is required when status is CANCELED.");
	}

	const requestData = {
		status: newStatus,
		delete_comment: delete_comment,
	};
	const data = await requestAPI(
		URL.STORE_ORDER_ID.replace("{id}", orderId),
		"PATCH",
		requestData
	);
	return data;
}
