import { requestAPI } from "../../../common/requests/RequestAPI";
import URL from "../../../common/requests/UrlsEnum";

export async function CreateUploadURL(groupId, fileName) {
	let data = await requestAPI(
		URL.ONEDRIVE_UPLOAD_URL.replace("{id}", groupId),
		"POST",
		{
			fileName,
		}
	);
	return data.data;
}

export async function UploadFile(
	groupId,
	file,
	name,
	uploadUrl,
	size,
	contentRange
) {
	const formData = new FormData();

	formData.append("file", file, name);
	formData.append("url", uploadUrl);
	formData.append("size", size);
	formData.append("contentRange", contentRange);
	let data = await requestAPI(
		URL.ONEDRIVE_UPLOAD.replace("{id}", groupId),
		"POST",
		formData,
		undefined,
		true
	);
	return data.data;
}
