import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import CustomButton from "../../../../common/layouts/Global/CustomButton";
import FloatingLabelInput from "../../../../common/layouts/Global/InputFloatingLabel";
import PopupHeadTitle from "../../../../common/layouts/Global/PopupHeadTitle";
import {
	RoundButtonXMark,
	RoundButtonXMarkAbsolute,
} from "../../../../common/layouts/Global/RoundButton";
import { ToastNotSavedCustom } from "../../../../common/util/ToastUtil";
import { FetchMachineGroup } from "../../../requests/machines/FetchAllMachines";
import { MachineFilter } from "../MachineFilter";
import { UsersFilter } from "../UsersFilter";

const getCurrentDateHour = (currentDate) => {
	if (!currentDate) return;
	const yyyy = currentDate.getFullYear();
	const mm = String(currentDate.getMonth() + 1).padStart(2, "0");
	const dd = String(currentDate.getDate()).padStart(2, "0");
	const hh = String(currentDate.getHours()).padStart(2, "0");
	const min = String(currentDate.getMinutes()).padStart(2, "0");
	return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
};

function CreateDiscountPopup({ open, closeMethod, createMethod, groupId }) {
	const { t } = useTranslation();

	const [id, setId] = useState(1);
	const [users, setUsers] = useState([
		{ id: 0, email: null, initial_uses: 0, uses_left: 0, unlimited: 1 },
	]);
	const [selectedIds, setSelectedIds] = useState(["all"]);

	const discountNameInput = useRef(null);
	const discountCodeInput = useRef(null);
	const discountValueInput = useRef(null);
	const discountStartDateInput = useRef(null);
	const discountEndDateInput = useRef(null);
	const [isApplicationChecked, setIsApplicationChecked] = useState(true);
	const [isMachineChecked, setIsMachineChecked] = useState(true);
	const [machines, setMachines] = useState([]);

	useEffect(() => {
		const resetUsers = () => {
			setUsers([newUser]);
			setSelectedIds(["all"]);
		};
		if (open) {
			resetUsers();
		}
	}, [open]);
	useEffect(() => {
		const fetchMachines = async () => {
			const fetchMachines = await FetchMachineGroup(groupId);
			const options = [];
			fetchMachines?.map((machine) =>
				options.push({
					id: machine.id,
					name: machine?.machine_name?.private_name,
				})
			);
			setMachines(options);

			setSelectedIds(["all"]);
		};

		fetchMachines();
	}, [groupId]);

	const newUser = {
		id: 0,
		email: null,
		initial_uses: 0,
		uses_left: 0,
		unlimited: 1,
	};
	const handleAddUser = () => {
		setId((prev) => prev + 1);

		setUsers((prevUsers) => {
			const filteredUsers = prevUsers.filter(
				(user) => user.email !== null
			);

			return [
				...filteredUsers,
				{ ...newUser, id, email: "", uses_left: 0 },
			];
		});
	};

	const handleDeleteUser = (userId) => {
		const updatedUsers = users.filter((user) => user.id !== userId);
		setUsers(updatedUsers.length ? updatedUsers : [newUser]);
	};

	const handleUserUpdate = (userId, field, newValue) => {
		setUsers((prevUsers) =>
			prevUsers.map((user) =>
				user.id === userId ? { ...user, [field]: newValue } : user
			)
		);
	};
	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};
	const handleSubmit = () => {
		const discountName = discountNameInput.current.value;
		const discountCode =
			discountCodeInput.current.value === ""
				? null
				: discountCodeInput.current.value;
		const discountValue = Number(discountValueInput.current.value);
		let discountStartDate = discountStartDateInput.current.value
			? new Date(discountStartDateInput.current.value)
			: null;
		let discountEndDate = discountEndDateInput.current.value
			? new Date(discountEndDateInput.current.value)
			: null;

		const isEmpty = (value, message) => {
			if (value === "") {
				ToastNotSavedCustom(message);
				return true;
			}
			return false;
		};

		const isInvalidCode = (code) => {
			if (!/^[a-zA-Z0-9]+$/.test(code)) {
				ToastNotSavedCustom(t("discount.code.invalid"));
				return true;
			}
			return false;
		};

		const isOutOfRange = (value, min, max, message) => {
			if (value <= min || value > max) {
				ToastNotSavedCustom(message);
				return true;
			}
			return false;
		};

		if (isEmpty(discountName, t("discount.name.invalid"))) return;

		if (!discountCode && !users.every((user) => user.unlimited === 1)) {
			ToastNotSavedCustom(t("discount.code.unlimited"));
			return;
		}

		if (
			discountCode?.toString().length < 4 &&
			discountCode?.toString().length > 0
		) {
			ToastNotSavedCustom(t("discount.code.invalid.length"));
			return;
		}
		if (discountCode?.toString().length >= 4 && isInvalidCode(discountCode))
			return;
		if (isOutOfRange(discountValue, 0, 100, t("discount.value.between")))
			return;

		if (!Number.isInteger(discountValue)) {
			ToastNotSavedCustom(t("discount.value.invalid"));
			return;
		}
		if (isEmpty(discountStartDate, t("discount.start.date.invalid")))
			return;

		if (
			discountEndDate &&
			!moment(discountStartDate).isBefore(moment(discountEndDate))
		) {
			ToastNotSavedCustom(t("discount.date.diff.invalid"));
			return;
		}
		const nullUsers = users.filter((user) => user.email === null);
		const invalidEmailUsers = users.filter(
			(user) => user.email !== null && !isValidEmail(user.email)
		);
		if (nullUsers.length && users.length > 1) {
			ToastNotSavedCustom(t("discount.email.null"));
			return;
		}
		if (invalidEmailUsers.length) {
			ToastNotSavedCustom(t("discount.email.invalid"));
			return;
		}
		const invalidUseUsers = users.filter(
			(user) => !user.unlimited && user.initial_uses === 0
		);
		if (invalidUseUsers.length) {
			ToastNotSavedCustom(t("discount.initial.zero"));
			return;
		}
		const invalidUseNumberUsers = users.filter((user) =>
			isNaN(user.initial_uses)
		);
		if (invalidUseNumberUsers.length) {
			ToastNotSavedCustom(t("discount.initial.notNumber"));
			return;
		}

		discountStartDate = discountStartDate.toISOString();
		if (discountEndDate) {
			discountEndDate = discountEndDate.toISOString();
		} else {
			discountEndDate = null;
		}

		createMethod(
			discountName,
			discountCode,
			discountValue / 100,
			discountStartDate,
			discountEndDate,
			isMachineChecked,
			isApplicationChecked,
			selectedIds,
			users
		);
	};
	const rows = [
		...users?.map((User) => {
			return [
				User.email === null ? (
					t("all.users")
				) : (
					<FloatingLabelInput
						className={"w-[100px]"}
						value={User.email}
						onChange={(e) =>
							handleUserUpdate(User.id, "email", e.target.value)
						}
					/>
				),
				<FloatingLabelInput
					className={"w-[100px]"}
					value={User.initial_uses}
					disabled={User.unlimited}
					min={0}
					type="number"
					onChange={(e) =>
						handleUserUpdate(
							User.id,
							"initial_uses",
							parseInt(e.target.value)
						)
					}
				/>,

				<input
					type="checkbox"
					checked={User.unlimited}
					onChange={(e) =>
						handleUserUpdate(
							User.id,
							"unlimited",
							e.target.checked ? 1 : 0
						)
					}
				/>,
				User.email !== null && (
					<RoundButtonXMark
						functionCall={() => handleDeleteUser(User.id)}
					/>
				),
			];
		}),
		[<CustomButton text={t("add")} onClick={handleAddUser} />],
	];
	return (
		<Popup
			open={open}
			onClose={closeMethod}
			contentStyle={{
				borderRadius: "0.75rem",
				//overflow: "auto",
				padding: "10px",
				maxHeight: "95%",
				width: "90%",
				maxWidth: "600px",
			}}
			className="relative w-2/3"
		>
			<RoundButtonXMarkAbsolute functionCall={closeMethod} />

			<PopupHeadTitle text={t("create.discount.title")} />
			<div className="text-center mt-2 p-1 h-auto max-h-[500px]  overflow-auto">
				<div
					style={{
						//display: "flex",
						//flexDirection: "column",
						alignItems: "center",
						overflow: "auto",
					}}
				>
					<div className="w-5/6">
						<div className="w-full">
							<FloatingLabelInput
								className={"w-full"}
								type="text"
								name={t("name")}
								reference={discountNameInput}
							/>
						</div>
						<div className="sm:flex xs:block gap-2 sm:my-2 w-full">
							<FloatingLabelInput
								className={"w-full"}
								type="text"
								name={
									t("create.discount.code") +
									" " +
									t("optional")
								}
								reference={discountCodeInput}
							/>
							<FloatingLabelInput
								type="number"
								className={"w-full"}
								min={0}
								max={100}
								name={t("create.discount.value")}
								reference={discountValueInput}
							/>
						</div>
						<div className="sm:flex xs:block ">
							<FloatingLabelInput
								className={"w-full"}
								reference={discountStartDateInput}
								min={getCurrentDateHour(new Date())}
								type="datetime-local"
								defaultValue={getCurrentDateHour(new Date())}
								name={t("date.start")}
							/>
							<FloatingLabelInput
								className={"w-full"}
								reference={discountEndDateInput}
								min={getCurrentDateHour(new Date())}
								type="datetime-local"
								name={t("date.end") + " " + t("optional")}
							/>
						</div>
					</div>
					<div className="text-sm sm:text-base mt-2 text-left">
						{t("discount.activate")} :
					</div>
					<div className="text-xs sm:text-base flex gap-2 my-2">
						<div
							className={` cursor-pointer rounded-sm ${
								isApplicationChecked
									? "bg-kupablue hover:bg-kupablue/80 text-white p-2 drop-shadow-[0_5px_5px_rgba(0,0,0,0.1)]"
									: "bg-white text-kupablue p-2 ring-1 ring-kupablue hover:bg-kupablue/10 "
							}`}
							onClick={() =>
								setIsApplicationChecked((prev) => !prev)
							}
						>
							{t("APP")}
						</div>
						<div
							className={` cursor-pointer rounded-sm ${
								isMachineChecked
									? "bg-kupablue hover:bg-kupablue/80 text-white p-2 drop-shadow-[0_5px_5px_rgba(0,0,0,0.1)]"
									: "bg-white text-kupablue p-2 ring-1 ring-kupablue hover:bg-kupablue/10 "
							}`}
							onClick={() => setIsMachineChecked((prev) => !prev)}
						>
							{t("station")}
						</div>
					</div>
					<div className="w-full sm:w-auto pl-2 sm:p-0 align-left text-left">
						<MachineFilter
							machineIds={machines}
							selectedIds={selectedIds}
							setSelectedIds={setSelectedIds}
						/>
						<UsersFilter
							rows={rows}
							columns={[
								t("email"),
								t("discounts.initial_uses"),
								t("discounts.unlimited"),
								t("actions"),
							]}
							users={users}
							firstUser={users[0]?.email}
						/>
					</div>
				</div>
				<div className="flex justify-end">
					<CustomButton
						className=" w-40 "
						text={t("create")}
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</Popup>
	);
}

export default CreateDiscountPopup;
