import React, { useContext } from "react";
import ReactPaginate from "react-paginate";
import { DimensionsContext } from "../../../common/context/DimensionsContext";

function Pagination({ pageCount, onPageChange, currentPage }) {
	const { dimensions } = useContext(DimensionsContext);
	return (
		<ReactPaginate
			previousLabel={dimensions > 1000 ? `<` : "<"}
			nextLabel={dimensions > 1000 ? `>` : ">"}
			breakLabel="..."
			pageRangeDisplayed={dimensions > 1100 ? 3 : 2}
			pageCount={pageCount}
			marginPagesDisplayed={dimensions > 1100 ? 2 : 1}
			onPageChange={onPageChange}
			forcePage={currentPage - 1}
			containerClassName="text-white flex gap-2 bg-opacity-60 text-white"
			previousLinkClassName="pagination-link"
			nextLinkClassName="pagination-link"
			breakLinkClassName="pagination-link"
			pageLinkClassName="pagination-link"
			activeLinkClassName="pagination-link-active cursor-default"
		/>
	);
}
export default Pagination;
