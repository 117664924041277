import {
	faCheckCircle,
	faExclamationCircle,
	faInfoCircle,
	faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import { useAuth } from "../../../../common/context/AuthContext";
import LoadingSpinner from "../../../../common/layouts/Global/LoadingSpinner";
import PopupHeadTitle from "../../../../common/layouts/Global/PopupHeadTitle";
import {
	RoundButtonLock,
	RoundButtonXMark,
	RoundButtonXMarkAbsolute,
} from "../../../../common/layouts/Global/RoundButton";
import TooltipNew from "../../../../common/layouts/Global/TooltipNew";
import Table from "../../../../common/layouts/tables/Table";
import Warning from "../../../../common/assets/warning.png";
import {
	ToastAfterLoading,
	ToastLoading,
	ToastNotSaved,
} from "../../../../common/util/ToastUtil";
import {
	deleteVideo,
	getGallery,
	getVideoPreview,
	modifyVideo,
} from "../../../requests/video/videoRequest";
function ModifyVideosPopup({ open, closeMethod, groupId, refetch }) {
	const { t } = useTranslation();
	const { staff } = useAuth();

	const [videoId, setVideoId] = useState(null);
	const { data: preview, isFetching } = useQuery([`previewUrl-${videoId}`], {
		queryFn: async () => await getVideoPreview(videoId),
		enabled: !!videoId,
		refetchInterval: 5 * 60 * 1000,
		refetchOnWindowFocus: false,
	});
	const { data: videos, refetch: refetchGalery } = useQuery(
		[`group-${groupId}-gallery`],
		{
			queryFn: () => getGallery(groupId),
		}
	);
	useEffect(() => {
		setVideoId(null);
	}, [open]);
	const stylesVideo = {
		height: "60px",
		width: "100px",
	};
	const handleDelete = async (videoId) => {
		ToastLoading("deleteVideo");
		const data = await deleteVideo(videoId);
		refetchGalery();
		ToastAfterLoading("deleteVideo", data?.success, data?.data?.message);
		if (!data.success) {
			return;
		}
	};

	const columns = [
		t("thumbnail"),
		t("name"),
		t("approved"),
		...(staff()
			? [
					<div className="flex gap-[4px] items-center ">
						{t("public")}
						<Popup
							trigger={
								<div className="cursor-pointer">
									<FontAwesomeIcon
										icon={faInfoCircle}
										className={`${"text-blue-500"} `}
									/>
								</div>
							}
						>
							<div className="text-xs ">
								{t("video.public.info")}
							</div>
						</Popup>
					</div>,
			  ]
			: []),
		t("sub.groups"),
		t("action"),
	];
	const rows = videos?.data?.map((video) => [
		<div
			className="relative rounded overflow-hidden cursor-pointer"
			style={{
				...stylesVideo,
				backgroundColor: "transparent",
				boxShadow: "rgba(0, 0, 0, 0.14) 0px 3px 4px",
				margin: "2px",
				backgroundImage: `url(${
					(video &&
						video.thumbnail &&
						video.thumbnail[0]?.large?.url) ||
					Warning
				})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
			onClick={() => {
				setVideoId(video?.id);
			}}
		>
			<div className="absolute inset-0 bg-black opacity-30	"></div>
			<FontAwesomeIcon
				icon={faPlayCircle}
				className={`${"text-stone-50"} text-2xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
			/>
		</div>,
		<div>{video?.name}</div>,
		<TooltipNew text={video?.approved ? t("approved") : t("approbation")}>
			<FontAwesomeIcon
				icon={video?.approved ? faCheckCircle : faExclamationCircle}
				className={`${
					video?.approved ? "text-kupagreenhover" : "text-orange-500"
				} text-base`}
			/>
		</TooltipNew>,
		...(staff() && !video.private
			? [
					<input
						type="checkbox"
						checked={video?.public}
						onChange={async () => {
							const data = await modifyVideo(
								video.id,
								!video.public,
								video.shared_with_subgroup
							);
							if (!data.success) {
								ToastNotSaved();
								return;
							}
							await refetch();
						}}
					/>,
			  ]
			: []),
		!video.private && (
			<input
				type="checkbox"
				checked={video?.shared_with_subgroup}
				onChange={async () => {
					const data = await modifyVideo(
						video.id,
						video.public,
						!video.shared_with_subgroup
					);
					if (!data.success) {
						ToastNotSaved();
						return;
					}
					await refetch();
				}}
			/>
		),

		!video.private ? (
			<RoundButtonXMark
				functionCall={() => {
					handleDelete(video.id);
				}}
			/>
		) : (
			<RoundButtonLock />
		),
	]);
	return (
		<Popup
			open={open}
			onClose={closeMethod}
			contentStyle={{
				borderRadius: "0.75rem",
				padding: "10px",
				maxHeight: "95%",
				width: "90%",
				maxWidth: "1100px",
			}}
			className="relative w-2/3"
		>
			<RoundButtonXMarkAbsolute functionCall={closeMethod} />
			<PopupHeadTitle text={t("videos.settings")} />
			<div className="max-h-[550px] lg:max-h-[550px] lg:flex  overflow-auto">
				<div className="lg:w-2/3">
					<Table
						className={"h-2/3 lg:h-auto max-h-[500px]"}
						rows={rows}
						columns={columns}
					/>
				</div>
				<div className="flex lg:align-middle lg:items-center lg:justify-center max-h-[550px]  lg:w-1/3 lg:translate-y-0 translate-y-0">
					{preview?.data?.url ? (
						<iframe
							className=" mt-2 flex justify-center align-middle"
							src={preview?.data?.url}
							height="200"
							width="300"
							id="preview"
							title="Iframe Example"
						></iframe>
					) : isFetching ? (
						<LoadingSpinner className={"h-[200px] w-[300px]"} />
					) : (
						<div className="p-6">{t("click.video.preview")}</div>
					)}
				</div>
			</div>
		</Popup>
	);
}

export default ModifyVideosPopup;
