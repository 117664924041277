import Wave from "react-wavify";

function BackgroundWave({ height, flip, isMobile }) {
	let options = {
		height: 1,
		amplitude: 1.5,
		speed: 0.5,
	};

	const getOptions = (points) => {
		return {
			...options,
			points: points,
		};
	};

	return (
		<>
			<Wave
				style={{
					position: "fixed",
					bottom: !flip ? "0px" : undefined,
					zIndex: "2",
					transform: flip && "rotate(180deg)",
					height:
						height ??
						(isMobile ? "160px" : flip ? "80px" : "100px"),
				}}
				fill="url(#gradient)"
				options={getOptions(5)}
			>
				<defs>
					<linearGradient
						id="gradient"
						gradientTransform="rotate(100)"
					>
						<stop offset="100%" stopColor="#00aed3" />
					</linearGradient>
				</defs>
			</Wave>
			{/* <Wave
				fill="#f79902"
				paused={false}
				options={{
					height: -11,
					//amplitude: ,
					speed: 0.015,
					points: 3,
				}}
			/> */}
		</>
	);
}

export default BackgroundWave;
