import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next, { t } from "i18next";
import { useState } from "react";
import { UpdateUser } from "../../requests/user/Me";

function LanguageSwitcherNew({ className }) {
	const getNextLanguage = () => {
		let langs = ["fr", "en"];
		let currentLang = i18next.languages[0];

		const currentIndex = langs.indexOf(currentLang);
		const nextIndex = (currentIndex + 1) % langs.length;
		return langs[nextIndex];
	};
	const [test, setTest] = useState(0);

	const switchLanguage = () => {
		i18next.changeLanguage(getNextLanguage());
		UpdateUser({ language: i18next.languages[0] });
		setTest(test + 1);
	};

	return (
		<button
			onClick={switchLanguage}
			className={`flex items-left mr-auto p-2  w-full font-semibold text-black  hover:bg-kupabluee hover:text-kupawhite`}
		>
			<FontAwesomeIcon icon={faLanguage} className=" text-xs" />
			<span className="ml-3  xl:block text-xs">
				{t("language", {
					lng: getNextLanguage(),
				})}
			</span>
		</button>
	);
}

export default LanguageSwitcherNew;
