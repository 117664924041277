import { useNavigate } from "react-router-dom";
import logo from "../../../common/assets/logo/kupa.png";

function LogoLogin({
	isMobile,
	login = false,
	actionLogo,
	customWidthLogin = "350px",
	customWidth = "250px",
}) {
	const logoStyles = {
		padding: isMobile ? "15px" : "15px",
		maxWidth: isMobile ? "19vh" : login ? customWidthLogin : customWidth,
	};

	const navigate = useNavigate();

	return (
		<div
			style={{
				cursor: "pointer",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
			}}
			onClick={() => {
				navigate(`/login}`);
				actionLogo();
			}}
		>
			<img src={logo} style={logoStyles} alt="KupaStation Logo" />
		</div>
	);
}

export default LogoLogin;
