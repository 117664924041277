import { requestAPI } from "../../../common/requests/RequestAPI";
import URL from "../../../common/requests/UrlsEnum";

export async function getGallery(groupId) {
	let data = await requestAPI(URL.GALLERY.replace("{id}", groupId));
	return data;
}
export async function getSequences(groupId) {
	let data = await requestAPI(URL.SEQUENCES.replace("{id}", groupId));
	return data.success ? data.data : [];
}
export async function getSequence(groupId, sequenceId) {
	let data = await requestAPI(
		URL.SEQUENCE.replace("{id}", groupId).replace(
			"{sequenceId}",
			sequenceId
		)
	);
	return data.success ? data.data : null;
}
export async function getVideoPreview(videoId) {
	let data = await requestAPI(URL.VIDEO.replace("{id}", videoId));
	return data.success ? data : null;
}
export async function modifyVideo(
	videoId,
	shared_public,
	shared_with_subgroup
) {
	let data = await requestAPI(URL.VIDEO.replace("{id}", videoId), "PUT", {
		shared_public,
		shared_with_subgroup,
	});
	return data.success ? data : null;
}
export async function addSequence(groupId, name) {
	let data = await requestAPI(
		URL.SEQUENCES.replace("{id}", groupId),
		"POST",
		{
			name,
		}
	);

	return data;
}
export async function editSequence(groupId, sequenceId, sequence) {
	let data = await requestAPI(
		URL.SEQUENCE.replace("{id}", groupId).replace(
			"{sequenceId}",
			sequenceId
		),
		"PUT",
		{
			sequenceId,
			sequence,
		}
	);
	return data;
}
export async function deleteSequence(groupId, sequenceId) {
	let data = await requestAPI(
		URL.SEQUENCE.replace("{id}", groupId).replace(
			"{sequenceId}",
			sequenceId
		),
		"DELETE",
		{}
	);
	return data;
}
export async function deleteVideo(videoId) {
	let data = await requestAPI(URL.VIDEO.replace("{id}", videoId), "DELETE", {
		fileId: videoId,
	});

	return data;
}
