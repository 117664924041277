import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import WhiteBlock from "./WhiteBlock";
const WhiteBlockDiv = ({
	children,
	text,
	text2,
	className,
	classNameText,
	classNameChild,
}) => {
	const location = useLocation();
	const resultM = location.pathname.includes("management");
	const containerWB = useRef(null);

	useEffect(() => {
		if (containerWB.current) {
			const childrenElements = Array.from(containerWB.current.children);

			const hasTable = childrenElements.some((child) => {
				const tableElement = child.querySelector("#maintable");
				if (tableElement && resultM) {
					tableElement.style.maxHeight = "350px";
				}
				return !!tableElement;
			});
		}
	}, []);
	return (
		<WhiteBlock className={className}>
			{(text || text2) && (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						padding: "15px",
						paddingTop: "5px",
						paddingBottom: "5px",
						justifyContent:
							text && text2
								? "space-between"
								: text2
								? "end"
								: "start",
					}}
				>
					<h1
						className="text-four font-bold"
						style={{
							fontSize: "16px",
						}}
					>
						{text}
					</h1>
					<h1
						className="text-four font-bold"
						style={{
							fontSize: "16px",
						}}
					>
						{text2}
					</h1>
				</div>
			)}

			<div
				ref={containerWB}
				className={classNames("flex", classNameChild)}
				style={{
					paddingRight: "15px",
					paddingLeft: "15px",
					paddingBottom: "15px",
					flex: 1,
					/* maxHeight: resultM && hastable && "350px", */
				}}
			>
				{children}
			</div>
		</WhiteBlock>
	);
};
export default WhiteBlockDiv;
