import { useRef } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import CustomButton from "../../../../common/layouts/Global/CustomButton";
import FloatingLabelInput from "../../../../common/layouts/Global/InputFloatingLabel";
import PopupHeadTitle from "../../../../common/layouts/Global/PopupHeadTitle";
import { RoundButtonXMarkAbsolute } from "../../../../common/layouts/Global/RoundButton";
import { ToastNotSavedCustom } from "../../../../common/util/ToastUtil";

function ModifyRolePopup({ open, closeMethod, modifyMethod, currentRole }) {
	const { t } = useTranslation();
	const roleNameInput = useRef(null);

	return (
		<Popup
			open={open}
			onClose={closeMethod}
			contentStyle={{
				borderRadius: "0.75rem",
				width: "auto",
				padding: "10px",
			}}
			className="relative"
		>
			<RoundButtonXMarkAbsolute functionCall={closeMethod} />

			<PopupHeadTitle text={t("modify.role.title")} />
			<div className="text-center p-1 mt-2">
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<FloatingLabelInput
						name={t("create.role.roleName")}
						defaultValue={currentRole.name}
						reference={roleNameInput}
					/>
				</div>
				<div className="flex justify-end">
					<CustomButton
						className="w-40 mt-1"
						text={t("modify")}
						onClick={() => {
							const roleName = roleNameInput.current.value;
							if (roleName === "") {
								ToastNotSavedCustom(t("Role name invalid"));
							} else {
								modifyMethod(roleName);
							}
						}}
					/>
				</div>
			</div>
		</Popup>
	);
}
export default ModifyRolePopup;
