import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import CustomButton from "../../../common/layouts/Global/CustomButton";
import PopupHeadTitle from "../../../common/layouts/Global/PopupHeadTitle";
import { RoundButtonXMarkAbsolute } from "../../../common/layouts/Global/RoundButton";
import TooltipNew from "../../../common/layouts/Global/TooltipNew";
import Table from "../../../common/layouts/tables/Table";
import { GetRecipeImg } from "../../../common/util/RecipeUtil";
import { ToastNotSavedCustom } from "../../../common/util/ToastUtil";
import DeletePopup from "../../../portail/layouts/Dashboard/Popup/DeletePopup";
import QuitSave from "../../../portail/layouts/Machine/QuitSave";
import { FetchOrder } from "../../requests/Orders";

function DetailsOrderPopup({
	open,
	closeMethod,
	acceptOrder,
	cancelOrder,
	orderId = null,
	isMaster,
}) {
	const { t } = useTranslation();
	const { data, refetch } = useQuery([`order-${orderId}`], {
		queryFn: () => FetchOrder(orderId),
		enabled: !!orderId,
	});
	const [deleteOrderPopupOpen, setDeleteOrderPopupOpen] = useState(false);
	const [savePopupOpen, setSavePopupOpen] = useState(false);
	const [newStatus, setNewStatus] = useState(null);
	const textAreaRef = useRef();

	if (!data?.success) return null;
	const order = data?.data;
	const StatusList = ["PENDING", "PROCESSING", "IN_TRANSIT", "DELIVERED"];
	const currentIndex = StatusList.indexOf(order?.status);
	let nextStatus;
	if (currentIndex !== -1 && currentIndex < StatusList.length - 1) {
		nextStatus = StatusList[currentIndex + 1];
	} else {
		nextStatus = null;
	}
	const columns = [t("products"), t("quantity")];
	const rows = order?.items?.map((el) => [
		<li key={el.key} className="flex align-middle">
			<img
				alt="KUPA STATION"
				src={GetRecipeImg(el.key)}
				className="h-8 w-8 object-contain mix-blend-multiply"
			/>
			<span className="h-8 flex align-middle p-1 text-sm 2xl:text-base">
				{t(el.key)}{" "}
			</span>
		</li>,
		<div className="">{el.quantity}</div>,
	]);
	const openDeletePopup = () => {
		setDeleteOrderPopupOpen(true);
	};
	const closeDeletePopup = () => {
		setDeleteOrderPopupOpen(false);
	};
	return (
		<>
			<Popup
				open={open}
				nested
				onClose={closeMethod}
				contentStyle={{
					borderRadius: "0.75rem",
					//width: "auto",
					padding: "20px",
					width: "90%",
					maxWidth: "600px",
				}}
				className="relative bg-white p-6 rounded-lg"
			>
				<RoundButtonXMarkAbsolute functionCall={closeMethod} />

				<PopupHeadTitle
					text={t("store.order.popup", { ref: order.ref_id })}
				/>

				<div className="mb-6 mt-2 p-4 pr-12">
					<Table rows={rows} columns={columns} />
					<div className="flex align-middle center">
						<div className=" py-1 mt-2">{t("status")} : </div>

						{isMaster && nextStatus ? (
							<>
								<select
									value={order.status}
									onChange={(e) => {
										setNewStatus(e.target.value);
										setSavePopupOpen(true);
									}}
									className="border border-slate-300 rounded-l-[5px] px-2 py-1 mt-2 ml-2"
								>
									{StatusList.slice(currentIndex).map(
										(statut) => {
											return (
												<option
													key={statut}
													value={statut}
													selected={
														order.status === statut
													}
												>
													{t(statut)}
												</option>
											);
										}
									)}
								</select>
								<TooltipNew
									text={`${t("next.status")} : ${t(
										nextStatus
									)}`}
								>
									<button
										className={
											" font-bold rounded-r-[5px]  border-t-none shadow-md  bg-kupabluedark/[80%] text-white hover:shadow-md hover:bg-kupablue/60  px-2 py-1 mt-2"
										}
										text={t("Delete ")}
										type="delete"
										onClick={() => {
											setNewStatus(nextStatus);
											setSavePopupOpen(true);
										}}
									>
										<FontAwesomeIcon icon={faCaretRight} />
									</button>
								</TooltipNew>
								<Popup
									className="bg-white rounded-[5px]"
									open={savePopupOpen}
									onClose={() => setSavePopupOpen(false)}
									nested
									modal
									contentStyle={{
										borderRadius: "5px",
										//width: "auto",
										padding: "20px",
										width: "80%",
										maxWidth: "400px",
									}}
									position="top center"
								>
									{(close) => (
										<QuitSave
											secondText={`${t(
												"new.status"
											)} : ${t(newStatus)}`}
											close={() => {
												close();
											}}
											showNotSave={false}
											showSave={true}
											saveSettings={async () => {
												await acceptOrder(
													order,
													newStatus
												);
												refetch();
												setSavePopupOpen(false);
											}}
										/>
									)}
								</Popup>
							</>
						) : (
							<div className="px-2 py-1 mt-2">
								{t(order?.status)}
							</div>
						)}
					</div>
					{order?.status === "CANCELED" && (
						<div className="">
							{t("delete.reason")} :
							<textarea
								readOnly
								value={order?.delete_comment}
								className="ring-1 ring-black w-full p-1 outline-none font-thin"
							></textarea>
						</div>
					)}
				</div>
				<div className="flex gap-[5px] justify-end ">
					{order?.status !== "CANCELED" &&
						order?.status !== "DELIVERED" && (
							<div className="flex gap-[5px]">
								{(isMaster ||
									(!isMaster &&
										order.status === "PENDING")) && (
									<>
										<CustomButton
											className="md:w-40 w-32 text-center"
											text={t("cancel")}
											type="delete"
											onClick={() => {
												openDeletePopup();
											}}
										/>
									</>
								)}
							</div>
						)}
					<CustomButton
						className="md:w-40 w-32 text-center"
						text={t("close")}
						type="cancel"
						onClick={closeMethod}
					/>
				</div>
			</Popup>
			<DeletePopup
				open={deleteOrderPopupOpen}
				nested={true}
				closeMethod={closeDeletePopup}
				deleteMethod={async () => {
					if (!textAreaRef.current.value) {
						ToastNotSavedCustom(t("leave.comment.please"));
						return;
					}
					await cancelOrder(order, textAreaRef.current.value);
					refetch();
					closeDeletePopup();
				}}
				typeOfDelete={"sotre.order"}
				customDiv={
					<div className="mt-2">
						{t("command.comment")}
						<textarea
							ref={textAreaRef}
							className="ring-1 ring-black w-full p-1 outline-none "
						></textarea>
					</div>
				}
			></DeletePopup>
		</>
	);
}

export default DetailsOrderPopup;
