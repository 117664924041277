import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../common/layouts/Global/LoadingSpinner";
import { FetchTree } from "../../common/requests/tree/Tree";

const Store = () => {
	const { t } = useTranslation();

	document.title = `KUPA Station | ${t("store")}`;

	const { data } = useQuery([`tree`], {
		queryFn: () => FetchTree(),
	});

	const navigate = useNavigate();

	useEffect(() => {
		if (data && data.length > 0) {
			navigate(`/store/${data[0].id}`);
		}
	}, [data, navigate]);

	if (!data) return <LoadingSpinner />;

	if (data.length === 0) return <></>;
};
export default Store;
