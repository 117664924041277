import { useTranslation } from "react-i18next";
import ErrorTable from "../../layouts/tables/ErrorTable";

function Errors() {
	const { t } = useTranslation();
	document.title = `KUPA Station | ${t("errors")}`;

	return (
		<div>
			<ErrorTable />
		</div>
	);
}

export default Errors;
