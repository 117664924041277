import { useQuery } from "@tanstack/react-query";
import Filters from "../../../common/layouts/filters/core/Filters";
import {
	FetchVisibleLogs,
	FetchVisibleMachines,
} from "../../requests/machines/DrinkHistory";

function LogFilters({ handleNewFilters }) {
	const groupId = 1;

	const { data: visibleMachineData } = useQuery(
		[`visibleMachines-${groupId}`],
		{
			queryFn: async () => await FetchVisibleMachines(groupId),
		}
	);
	const { data: visibleLogsData } = useQuery([`visibleLogs-${groupId}`], {
		queryFn: async () => await FetchVisibleLogs(groupId),
	});

	const availableFilters = [
		{
			name: "logs.type",
			type: "checkbox",
			sql: ["type_id"],
			values: visibleLogsData,
		},
		{
			name: "email",
			type: "string",
			sql: ["user", "email"],
		},
		{
			name: "stations",
			type: "checkbox",
			sql: ["machine_id"],
			values: visibleMachineData,
		},
		{
			name: "date",
			type: "date",
			sql: ["created_at"],
		},
	];

	return (
		<div>
			<Filters
				availableFilters={availableFilters}
				handleNewFilters={handleNewFilters}
			/>
		</div>
	);
}

export default LogFilters;
