import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import CustomButton from "../../../../common/layouts/Global/CustomButton";
import Dropdown from "../../../../common/layouts/Global/Dropdown";
import FloatingLabelInput from "../../../../common/layouts/Global/InputFloatingLabel";
import PopupHeadTitle from "../../../../common/layouts/Global/PopupHeadTitle";
import { RoundButtonXMarkAbsolute } from "../../../../common/layouts/Global/RoundButton";
import {
	getSequence,
	getSequences,
} from "../../../requests/video/videoRequest";

const getCurrentDateHour = (currentDate) => {
	if (!currentDate) return;
	const yyyy = currentDate.getFullYear();
	const mm = String(currentDate.getMonth() + 1).padStart(2, "0");
	const dd = String(currentDate.getDate()).padStart(2, "0");
	const hh = String(currentDate.getHours()).padStart(2, "0");
	const min = String(currentDate.getMinutes()).padStart(2, "0");
	return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
};

function ModifyMachineVideoSchedulePopup({
	open,
	closeMethod,
	modifyMethod,
	groupId,
	currentVideoSchedule,
}) {
	const { t } = useTranslation();
	const startDate = useRef(null);
	const endDateRef = useRef(null);
	const [start, setStart] = useState(currentVideoSchedule.start);
	const { data: sequences } = useQuery([`group-${groupId}-sequences`], {
		queryFn: () => getSequences(groupId),
	});
	const [sequence, setsequence] = useState(
		currentVideoSchedule?.video_model?.name
	);
	const [selectedSequence, setSelectedSequence] = useState(
		currentVideoSchedule?.video_model?.id
	);
	useEffect(() => {
		const fetchSequence = async () => {
			if (currentVideoSchedule) {
				const fetchSequence = await getSequence(
					groupId,
					currentVideoSchedule?.video_model?.id
				);

				setsequence(fetchSequence ? fetchSequence.name : "");
			}
		};

		fetchSequence();
	}, [currentVideoSchedule, groupId]);

	useEffect(() => {
		if (open) {
			setStart(currentVideoSchedule.start);
			setSelectedSequence(currentVideoSchedule?.video_model?.id);
			setsequence(currentVideoSchedule?.video_model?.name);

			if (startDate.current) {
				startDate.current.value = getCurrentDateHour(
					new Date(currentVideoSchedule.start)
				);
			}
			if (endDateRef.current && currentVideoSchedule.end) {
				endDateRef.current.value = getCurrentDateHour(
					new Date(currentVideoSchedule.end)
				);
			}
		}
	}, [open, currentVideoSchedule]);
	return (
		<Popup
			open={open}
			onClose={closeMethod}
			contentStyle={{
				borderRadius: "0.75rem",
				width: "auto",
				padding: "10px",
			}}
			className="relative"
		>
			<RoundButtonXMarkAbsolute functionCall={closeMethod} />

			<PopupHeadTitle text={t("schedule.edit")} />

			<div className="text-center p-1 mt-2">
				<div
					className="m-auto "
					style={{
						gap: "0px",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Dropdown
						//className="mb-1 w-44"
						customTitle={sequence}
						list={sequences?.map((sequence) => ({
							title: sequence?.name,
							onClick: () => setSelectedSequence(sequence?.id),
						}))}
						name={t("sequence")}
					/>

					<FloatingLabelInput
						reference={startDate}
						min={getCurrentDateHour(new Date())}
						type="datetime-local"
						defaultValue={getCurrentDateHour(
							new Date(currentVideoSchedule.start)
						)}
						onChange={(e) => {
							setStart(e.target.value);
						}}
						name={t("date.start")}
					/>
					<FloatingLabelInput
						className=""
						type="datetime-local"
						defaultValue={
							currentVideoSchedule.end &&
							getCurrentDateHour(
								new Date(currentVideoSchedule.end)
							)
						}
						reference={endDateRef}
						min={getCurrentDateHour(new Date(start))}
						name={t("date.end")}
					/>
				</div>
				<div className="flex justify-end">
					<CustomButton
						className="w-40 mt-1"
						text={t("modify")}
						onClick={() => {
							if (groupId !== "") {
								modifyMethod(
									selectedSequence,
									startDate.current.value,
									endDateRef.current.value === ""
										? null
										: endDateRef.current.value
								);
							}
						}}
					/>
				</div>
			</div>
		</Popup>
	);
}
export default ModifyMachineVideoSchedulePopup;
