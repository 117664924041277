import {
	EmailAuthProvider,
	createUserWithEmailAndPassword,
	reauthenticateWithCredential,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInWithCustomToken,
	signInWithEmailAndPassword,
	signOut,
	updatePassword,
} from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";

import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import auth from "../config/firebase";
import LoadingSpinner from "../layouts/Global/LoadingSpinner";
import {
	FetchSelfInfos,
	ForceCheckEmailVerification,
	GeneratePin,
	UpdateUser,
} from "../requests/user/Me";
import { ToastAfterLoading, ToastLoading } from "../util/ToastUtil";

const AuthContext = createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(true);
	const [firebaseUser, setFirebaseUser] = useState();
	const [kupaUser, setKupaUser] = useState();

	function loginWithCustomToken(token) {
		return signInWithCustomToken(auth, token);
	}

	function login(email, password) {
		if (!email || !password) throw new Error();
		return signInWithEmailAndPassword(auth, email, password);
	}

	async function register(email, password, name) {
		if (!email || !password || !name) throw new Error();

		const user = await createUserWithEmailAndPassword(
			auth,
			email,
			password
		);

		setTimeout(async () => {
			await UpdateUser({ name: name, language: i18next.languages[0] });
			await refreshSelfInfos();
		}, 3000);

		return user;
	}

	function sendEmailPasswordReset(email) {
		if (!email) throw new Error();
		auth.languageCode = i18next.language || "en";
		return sendPasswordResetEmail(auth, email);
	}

	function logout() {
		return signOut(auth);
	}

	const refreshSelfInfos = async () => {
		const me = await FetchSelfInfos();
		setKupaUser(me?.data);

		return me;
	};

	const hasGlobalPermission = (permission) => {
		try {
			const permissions = kupaUser?.permissions?.global ?? [];
			return permissions?.includes(permission);
		} catch (error) {
			return false;
		}
	};
	const myPermission = async (groupId) => {
		return kupaUser?.permissions?.[groupId].length
			? kupaUser?.permissions?.[groupId]
			: kupaUser?.permissions?.global ?? [];
	};

	const hasPermission = (permission, groupId) => {
		try {
			const permissions = kupaUser?.permissions[String(groupId)] ?? [];
			const globalPermissions = kupaUser?.permissions?.global ?? [];
			return (
				permissions?.includes(permission) ||
				globalPermissions?.includes(permission)
			);
		} catch (error) {
			return false;
		}
	};

	const updateUsername = async (username) => {
		await UpdateUser({ name: username });
		await refreshSelfInfos();
	};

	const updatePasswordFunction = async (oldPassword, newPassword) => {
		try {
			const user = auth.currentUser;
			const credentials = EmailAuthProvider.credential(
				user.email,
				oldPassword
			);
			await reauthenticateWithCredential(auth.currentUser, credentials);
			await updatePassword(auth.currentUser, newPassword);
			return true;
		} catch (error) {
			return false;
		}
	};
	const inGroup = (groupId) => {
		try {
			const permissions = kupaUser?.groupMember ?? [];
			return permissions?.includes(parseInt(groupId));
		} catch (error) {
			return false;
		}
	};

	const myGroups = () => {
		try {
			const groups = kupaUser?.groupMember ?? [];
			return groups;
		} catch (error) {
			return [];
		}
	};
	const staff = () => {
		try {
			const staff = kupaUser?.permissions?.global?.length >= 1 ?? false;
			return staff;
		} catch (error) {
			return false;
		}
	};
	const generatePin = async () => {
		try {
			ToastLoading("generatingPin");
			const pin = await GeneratePin();
			setKupaUser({ ...kupaUser, pin: pin.data.pin });
			ToastAfterLoading("generatingPin", pin.success, pin?.data?.message);
		} catch (error) {
			return false;
		}
	};

	useEffect(() => {
		const sendEmailValidation = async (user) => {
			toast.dismiss();
			auth.languageCode = i18next.language;
			await sendEmailVerification(user);
			toast.success(
				t("auth.email.verification_sent", {
					0: user.email,
				})
			);
		};

		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			setFirebaseUser(user);
			setLoading(false);

			if (user) {
				const me = await refreshSelfInfos();

				if (!user.emailVerified) {
					toast.warning(t("auth.email.unverified.resend"), {
						onClick: () => sendEmailValidation(user),
						icon: "📧",
						autoClose: false,
					});

					toast.info(
						t("auth.email.unverified", {
							0: user.email,
						}),
						{
							onClick: () => sendEmailValidation(user),
							autoClose: false,
						}
					);
				}

				if (user.emailVerified && !me?.data?.user?.email_verified_at) {
					await ForceCheckEmailVerification();
				}
			} else {
				setKupaUser(undefined);
			}
		});

		return unsubscribe;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const value = {
		firebaseUser,
		kupaUser,
		refreshSelfInfos,
		login,
		loginWithCustomToken,
		register,
		sendEmailPasswordReset,
		logout,
		updateUsername,
		hasGlobalPermission,
		hasPermission,
		myPermission,
		inGroup,
		myGroups,
		staff,
		generatePin,
		updatePasswordFunction,
	};

	return loading ? (
		<LoadingSpinner size={200} />
	) : (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
}
