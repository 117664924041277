import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Permissions from "../../../common/config/permissions";
import { useAuth } from "../../../common/context/AuthContext";
import Restricted from "../../../common/context/hooks/Restricted";
import CustomButton from "../../../common/layouts/Global/CustomButton";
import WhiteBlockDiv from "../../../common/layouts/Global/WhiteBlockDiv";
import Table from "../../../common/layouts/tables/Table";
import { FetchRecipesAndOptions } from "../../../common/requests/machine/RecipesSupplements";
import { formattedNumber } from "../../../common/util/Format";
import {
	ToastAfterLoading,
	ToastLoading,
} from "../../../common/util/ToastUtil";
import OrderFilters from "../../layouts/filters/OrderFilters";
import {
	DownloadDrinkHistory,
	FetchDrinkHistory,
	FetchVisibleDiscounts,
} from "../../requests/machines/DrinkHistory";

function OrderTable({ groupId, machineId }) {
	const [filters, setFilters] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const { t } = useTranslation();

	const { data: recipeOptionData } = useQuery([`recipesAndOptions`], {
		queryFn: async () => await FetchRecipesAndOptions(),
	});

	const { data: visibleDiscountData } = useQuery(
		[`visibleDiscounts-${groupId}`],
		{
			queryFn: async () => await FetchVisibleDiscounts(groupId),
		}
	);

	const { data, isFetching, refetch } = useQuery(
		[`order-${groupId}${machineId && "-" + machineId}`],
		{
			queryFn: async () =>
				await FetchDrinkHistory(
					groupId,
					machineId,
					filters,
					currentPage,
					20
				),
		}
	);
	const { data: orders, pagination } = data || {};

	const handleNewFilters = async (newFilters) => {
		setCurrentPage(1);
		setFilters(newFilters);
	};

	useEffect(() => {
		refetch();
	}, [filters, currentPage, refetch]);

	const columns = [
		t("station"),

		filters.hasOwnProperty("user") ? t("user") : undefined,

		t("recipe"),

		t("options"),

		t("size"),

		t("origin"),

		filters.hasOwnProperty("order_discount") &&
		filters.order_discount.none === undefined
			? t("discount")
			: undefined,

		filters.hasOwnProperty("order_discount") &&
		filters.order_discount.none === undefined
			? t("real.price")
			: undefined,

		t("price"),

		t("date"),
	];

	const flattenedData = orders?.map((item) => {
		const optionNames = item?.order_option
			.map((optionIndex) =>
				t(recipeOptionData?.options[optionIndex?.option_id])
			)
			?.join(", ");

		const discountNames = item?.order_discount
			?.map((discount) => t(visibleDiscountData[discount.discount_id]))
			?.join(", ");

		const recipeName = t(recipeOptionData?.recipes[item.recipe.id]);

		return [
			item.machine?.machine_name?.private_name || "N/A",

			filters.hasOwnProperty("user")
				? item.user?.email || "N/A"
				: undefined,
			recipeName,

			optionNames || "N/A",

			item.container_size_ml + " ml",
			t(item.origin),

			filters.hasOwnProperty("order_discount") &&
			filters.order_discount.none === undefined
				? discountNames || "N/A"
				: undefined,

			filters.hasOwnProperty("order_discount") &&
			filters.order_discount.none === undefined
				? formattedNumber(item.real_price, 2).toLocaleString() + " $"
				: undefined,

			formattedNumber(item.paid_price, 2).toLocaleString() + " $",

			moment(item.created_at).format("YYYY-MM-DD HH:mm:ss"),
		];
	});

	const { hasPermission } = useAuth();
	return (
		<>
			{data && (
				<WhiteBlockDiv
					text={t("orders")}
					text2={`${
						data.pagination?.total?.toLocaleString() ?? ""
					} ${t("results")}  ${
						hasPermission(
							Permissions.GROUP_STATS_ORDER_REVENUE_VIEW,
							groupId
						)
							? "- " +
							  formattedNumber(
									data?.price?.paid_price,
									2
							  ).toLocaleString() +
							  "$"
							: ""
					}`}
				>
					<div className="w-full flex flex-col">
						<div className="pb-5 flex items-start">
							<OrderFilters
								machineId={machineId}
								handleNewFilters={handleNewFilters}
								groupId={groupId}
							/>

							<Restricted
								to={Permissions.GROUP_STATS_ORDER_EXPORT}
								groupId={groupId}
							>
								<CustomButton
									text={t("export")}
									className="ml-auto"
									onClick={async () => {
										ToastLoading(
											"export",
											t("export.progress")
										);
										const data = await DownloadDrinkHistory(
											groupId,
											machineId,
											filters
										);
										ToastAfterLoading(
											"export",
											data?.success,
											data?.success
												? t("export.success")
												: data?.data?.message
										);
									}}
									spinnerWhileExecuting={true}
								/>
							</Restricted>
						</div>

						<Table
							columns={columns}
							rows={flattenedData}
							isLoading={isFetching}
							pagination={pagination}
							currentPage={currentPage}
							handlePageChange={setCurrentPage}
						/>
					</div>
				</WhiteBlockDiv>
			)}
		</>
	);
}

export default OrderTable;
