import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Permissions from "../../../common/config/permissions";
import Restricted from "../../../common/context/hooks/Restricted";
import DiscountSection from "../../layouts/Dashboard/Sections/DetailsSection/DiscountSection";
import GroupVideoSchedule from "../../layouts/Dashboard/Sections/DetailsSection/GroupVideoSchedule";
import MachineVideoSchedule from "../../layouts/Dashboard/Sections/DetailsSection/MachineVideoSchedule";
import MembersSection from "../../layouts/Dashboard/Sections/DetailsSection/MembersSection";
import Notification from "../../layouts/Dashboard/Sections/DetailsSection/Notifications";
import RolesSection from "../../layouts/Dashboard/Sections/DetailsSection/RolesSection";
import { getGallery } from "../../requests/video/videoRequest";
import Gallery from "../Gallery";

function GroupManagement() {
	const { t } = useTranslation();
	document.title = `KUPA Station | ${t("management")}`;

	const { groupId } = useParams();
	const { data } = useQuery([`group-${groupId}-gallery`], {
		queryFn: () => getGallery(groupId),
	});

	return (
		<div className="space-y-[8px] 2xl:space-y-0  gap-2 2xl:grid 2xl:grid-cols-2">
			<Restricted to={Permissions.GROUP_MEMBER_VIEW} groupId={groupId}>
				<MembersSection groupId={groupId} />
			</Restricted>

			<Restricted to={Permissions.GROUP_ROLE_VIEW} groupId={groupId}>
				<RolesSection groupId={groupId} />
			</Restricted>
			<Restricted
				to={Permissions.GROUP_NOTIFICATION_VIEW}
				groupId={groupId}
			>
				<Notification groupId={groupId} />
			</Restricted>
			<Restricted to={Permissions.GROUP_DISCOUNT_VIEW} groupId={groupId}>
				<DiscountSection groupId={groupId} />
			</Restricted>
			{data?.success && (
				<Restricted to={Permissions.GROUP_VIDEO_VIEW} groupId={groupId}>
					<Gallery />
					<GroupVideoSchedule groupId={groupId}></GroupVideoSchedule>
					<MachineVideoSchedule
						groupId={groupId}
					></MachineVideoSchedule>
				</Restricted>
			)}
		</div>
	);
}

export default GroupManagement;
