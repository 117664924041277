const isValidPassword = (password) => password.length >= 8;

const isValidName = (name) => name.length >= 3;

const isValidMail = (email) =>
	email.match(
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	) !== null;

export { isValidMail, isValidName, isValidPassword };
