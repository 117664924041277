import { useQuery } from "@tanstack/react-query";
import Filters from "../../../common/layouts/filters/core/Filters";
import { FetchVisibleMachines } from "../../requests/machines/DrinkHistory";

function ErrorFilters({ handleNewFilters }) {
	const groupId = 1;

	const { data: visibleMachineData } = useQuery(
		[`visibleMachines-${groupId}`],
		{
			queryFn: async () => await FetchVisibleMachines(groupId),
		}
	);

	const availableFilters = [
		{
			name: "stations",
			type: "checkbox",
			sql: ["machine_id"],
			values: visibleMachineData,
		},
		{
			name: "date",
			type: "date",
			sql: ["created_at"],
		},
	];

	return (
		<div>
			<Filters
				availableFilters={availableFilters}
				handleNewFilters={handleNewFilters}
			/>
		</div>
	);
}

export default ErrorFilters;
