import { requestAPI } from "../../../common/requests/RequestAPI";
import URL from "../../../common/requests/UrlsEnum";

export async function FetchMemberNotif(groupId) {
	const memberNotif = await requestAPI(
		URL.NOTIF.replace("{id}", groupId),
		"GET"
	);
	return memberNotif;
}

export async function CreateMemberNotif(
	groupId,
	userEmail,
	active,
	active_subgroup,
	types = []
) {
	const data = await requestAPI(URL.NOTIF.replace("{id}", groupId), "POST", {
		userEmail,
		active,
		active_subgroup,
		types,
	});
	return data;
}

export async function DeleteMemberNotif(groupId, userId) {
	await requestAPI(URL.NOTIF.replace("{id}", groupId), "DELETE", {
		userId: userId,
	});
}

export async function ModifyMemberNotif(
	groupId,
	userId,
	active,
	active_subgroup,
	types
) {
	const data = await requestAPI(URL.NOTIF.replace("{id}", groupId), "PUT", {
		userId,
		active,
		active_subgroup,
		types,
	});
	return data;
}
