import React, { useState, createContext } from "react";

export const DimensionsContext = createContext({});

export const DimensionsProvider = ({ children }) => {
	const [dimensions, setDimensions] = useState(window.innerWidth);
	const [dimensionsHeight, setDimensionsHeight] = useState(
		window.innerHeight
	);

	const currentDate = new Date();

	const firstDayOfCurrentMonth = new Date(
		currentDate.getFullYear(),
		currentDate.getMonth(),
		1
	);
	firstDayOfCurrentMonth.setHours(0, 0, 0, 0);

	const endOfDay = new Date(currentDate);
	endOfDay.setHours(23, 59, 59, 999);

	const [startDateAPI, setStartDateAPI] = useState(
		firstDayOfCurrentMonth.toISOString()
	);
	const [endDateAPI, setEndDateAPI] = useState(endOfDay.toISOString());
	return (
		<DimensionsContext.Provider
			value={{
				dimensions,
				setDimensions,
				setDimensionsHeight,
				dimensionsHeight,
				startDateAPI,
				setStartDateAPI,
				endDateAPI,
				setEndDateAPI,
			}}
		>
			{children}
		</DimensionsContext.Provider>
	);
};
