import i18next from "i18next";
import { toast } from "react-toastify";

function ToastLoading(id, message) {
	const existingToast = toast.isActive(id);

	if (existingToast) {
		toast.update(id, {
			render: message ? message : i18next.t("loading"),
			isLoading: true,
			autoClose: false,
		});
	} else {
		toast.info(message ? message : i18next.t("loading"), {
			isLoading: true,
			autoClose: false,
			toastId: id,
		});
	}
}
function ToastAfterLoading(id, success, message) {
	toast.update(id, {
		render: message
			? i18next.t(message)
			: success
			? i18next.t("save.success")
			: i18next.t("save.error"),
		type: success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
		isLoading: false,
		autoClose: 3000,
	});
}
function ToastStartSaving() {
	return toast(i18next.t("save.in.progress"), {
		type: "info",
		autoClose: 4000,
		hideProgressBar: true,
	});
}

function ToastSaved() {
	return setTimeout(() => {
		toast(i18next.t("save.success"), {
			type: "success",
			autoClose: 4000,
			hideProgressBar: true,
		});
	}, 1);
}

function ToastNotSaved() {
	return toast(i18next.t("save.error"), {
		type: "error",
		autoClose: 4000,
		hideProgressBar: true,
	});
}
function ToastNotSavedCustom(info) {
	return toast(info, {
		type: "error",
		autoClose: 4000,
		hideProgressBar: true,
	});
}

export {
	ToastStartSaving,
	ToastSaved,
	ToastNotSaved,
	ToastNotSavedCustom,
	ToastLoading,
	ToastAfterLoading,
};
