import { toast } from "react-toastify";
import ForgotPassword from "./ForgotPassword.js";
import { useTranslation } from "react-i18next";

function ForgotPasswordForm({ handleForgotPassowrd, handleChangeForgot }) {
	const { t } = useTranslation();

	const handleSubmit = (email) => {
		if (email === "") {
			toast.error(t("fill.in.all.fields"));
		} else {
			handleForgotPassowrd(email);
		}
	};

	return (
		<ForgotPassword
			handleSubmit={handleSubmit}
			handleChangeForgot={handleChangeForgot}
		/>
	);
}

export default ForgotPasswordForm;
