import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../context/AuthContext";
import FloatingLabelInput from "../layouts/Global/InputFloatingLabel";
import InputPassword from "../layouts/Global/InputPassword";
import ProvidersLogin from "../layouts/Login/ProvidersLogin";
import {
	isValidMail,
	isValidName,
	isValidPassword,
} from "../util/formValidation";
function SignUpContainer() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { register } = useAuth();
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const handleRegister = async (e) => {
		e.preventDefault();
		if (!isValidName(name)) {
			toast.error(t("auth.invalid.username"));
			return;
		}

		if (!isValidMail(email)) {
			toast.error(t("auth.invalid.email"));
			return;
		}

		if (password !== confirmPassword) {
			toast.error(t("auth.password.mismatch"));
			return;
		}
		if (!isValidPassword(password)) {
			toast.error(t("auth.invalid.password"));
			return;
		}

		try {
			await register(email, password, name);
			navigate("/");
		} catch (e) {
			toast.error(t("auth.email.alreadyused"));
			return;
		}
	};

	return (
		<>
			<form className="flex flex-col align-middle justify-center w-full">
				<h1 className="text-kupablack text-lg uppercase flex p-1 mb-8 justify-center align-middle text-center">
					{t("signup.title")}
				</h1>
				<FloatingLabelInput
					darkmode={false}
					className={"mb-2  w-full  "}
					classNameInput="w-full p-2"
					name={t("signup.name")}
					onChange={(e) => setName(e.target.value)}
					value={name}
					type="text"
				/>
				<FloatingLabelInput
					darkmode={false}
					className={"mb-2  w-full  "}
					classNameInput="w-full p-2"
					name={t("email")}
					onChange={(e) => setEmail(e.target.value)}
					value={email}
					type="email"
					autoComplete="email"
				/>

				<InputPassword
					darkmode={false}
					classNameAll=" mb-2 w-60"
					classNameGlobal={
						"mb-2 flex w-full align-middle text-center justify-center"
					}
					name={t("auth.password")}
					onChange={(e) => setPassword(e.target.value)}
					value={password}
					autoComplete="current-password"
				/>
				<InputPassword
					darkmode={false}
					classNameAll="w-60"
					classNameGlobal={
						"mb-2 flex w-full align-middle text-center justify-center"
					}
					name={t("auth.password.confirm")}
					onChange={(e) => setConfirmPassword(e.target.value)}
					value={confirmPassword}
					autoComplete="current-password"
				/>

				<button
					onClick={handleRegister}
					type="submit"
					id="submit"
					className="whitespace-nowrap mt-4 bg-kupablue rounded-full p-2 text-kupawhite font-medium 
						text-md hover:bg-kupabluedark ease-in duration-200 transition-colors"
				>
					{t("auth.register")}
				</button>
				<div
					className="text-center text-sm whitespace-nowrap mt-2 rounded-full  font-medium 
				text-md  ease-in duration-200 transition-colors "
				>
					{t("auth.login_instead")}
					<p
						className="cursor-pointer text-kupablue"
						onClick={() => {
							navigate("/");
						}}
					>
						{t("sign.in")}
					</p>
				</div>

				<div className="border-t-2 border-slate-300 mt-5 mb-2" />

				<ProvidersLogin />
			</form>
		</>
	);
}

export default SignUpContainer;
