import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../common/layouts/tables/Table";

export function UsersFilter({
	rows = [],
	columns = [],
	users = [],
	firstUser,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const { t } = useTranslation();
	return (
		<div className=" relative">
			<div className="text-sm border-kupablue rounded-full pl-0 p-1 bg-white">
				<p
					className="inline cursor-pointer pl-0.5 p-2"
					onClick={() => setIsOpen((prev) => !prev)}
				>
					<FontAwesomeIcon
						className={`${
							isOpen ? "rotate-90" : ""
						} transition-transform ease-in duration-200 mr-2`}
						icon={faAngleRight}
					/>
					{t("users")} (
					{firstUser === null ? t("all.users") : users.length})
				</p>
			</div>

			{isOpen && (
				<div className="z-20  ">
					<Table
						className={"h-44 sm:h-52   w-auto  bg-white"}
						columns={columns}
						rows={rows}
					/>
				</div>
			)}
		</div>
	);
}
