import { useParams } from "react-router-dom";
import Permissions from "../../common/config/permissions";
import Restricted from "../../common/context/hooks/Restricted";
import StoreOrderTable from "../layouts/tables/StoreOrderTable";

const Orders = () => {
	const { groupId } = useParams();

	return (
		<div>
			<Restricted to={Permissions.STORE_ORDER_VIEW} groupId={groupId}>
				<StoreOrderTable key={groupId} groupId={groupId} />
			</Restricted>
		</div>
	);
};

export default Orders;
