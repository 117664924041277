import moment from "moment";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import CustomButton from "../../../../common/layouts/Global/CustomButton";
import PopupHeadTitle from "../../../../common/layouts/Global/PopupHeadTitle";
import { RoundButtonXMarkAbsolute } from "../../../../common/layouts/Global/RoundButton";
import Table from "../../../../common/layouts/tables/Table";
import { MachineList } from "../MachineFilter";

function DetailsDiscountPopup({ open, closeMethod, discount, groupId }) {
	const { t } = useTranslation();

	if (!discount) return null;

	return (
		<Popup
			open={open}
			onClose={closeMethod}
			contentStyle={{
				borderRadius: "0.75rem",
				//width: "auto",
				padding: "20px",
				width: "90%",
				maxWidth: "600px",
			}}
			className="relative bg-white p-6 rounded-lg"
		>
			<RoundButtonXMarkAbsolute functionCall={closeMethod} />

			<PopupHeadTitle text={t("more.details")} />

			<div className="mb-6 mt-2 p-4 pr-12">
				<div className=" text-left grid grid-cols-2 space-y-1 mb-2">
					<DetailItem
						label={t("discounts.description")}
						value={discount?.description}
					/>
					<DetailItem
						label={t("discounts.code")}
						value={
							discount?.discount_code ?? t("discounts.no.date")
						}
					/>
					<DetailItem
						label={t("discounts.value")}
						value={`${discount?.discount_value * 100}%`}
					/>
					<DetailItem
						label={t("date.start")}
						value={
							discount.start_date
								? moment(discount.start_date).format(
										"YYYY-MM-DD HH:mm"
								  )
								: t("discounts.no.date")
						}
					/>
					<DetailItem
						label={t("date.end")}
						value={
							discount.end_date
								? moment(discount.end_date).format(
										"YYYY-MM-DD HH:mm"
								  )
								: t("discounts.no.date")
						}
					/>

					<DetailItem
						label={t("discount.application")}
						value={discount?.available_app ? t("Oui") : t("Non")}
					/>
					<DetailItem
						label={t("discount.machine")}
						value={
							discount?.available_machine ? t("Oui") : t("Non")
						}
					/>
					<DetailItem
						label={t("discounts.machines")}
						value={
							discount?.discount_machine?.length ? (
								<MachineList
									machineNames={discount?.discount_machine?.map(
										(machine) => {
											return machine?.machine
												?.machine_name?.private_name;
										}
									)}
								/>
							) : (
								t("all.stations")
							)
						}
					/>
				</div>
				<div>
					<Table
						columns={[
							t("email"),
							t("discounts.uses_left"),
							t("discounts.unlimited"),
						]}
						rows={discount.discount_use?.map((Use) => {
							return [
								Use?.user ? Use.user.email : t("all.users"),
								Use.unlimited
									? "-"
									: `${Use.uses_left}/${Use.initial_uses}`,
								Use.unlimited ? t("Oui") : t("Non"),
							];
						})}
					/>
				</div>
			</div>
			<div className="flex justify-center">
				<CustomButton
					className="md:w-40 w-full text-center"
					text={t("close")}
					onClick={closeMethod}
				/>
			</div>
		</Popup>
	);
}

function DetailItem({ label, value }) {
	return (
		<>
			<div className="text-gray-600 mr-2">{label} :</div>
			<div>{value}</div>
		</>
	);
}

export default DetailsDiscountPopup;
