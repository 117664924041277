import { useState } from "react";
import { useTranslation } from "react-i18next";

import FloatingLabelInput from "../Global/InputFloatingLabel";

function ForgotPassword({ handleSubmit, handleChangeForgot }) {
	const [email, setEmail] = useState("");

	const { t } = useTranslation();

	const handleEmail = (e) => {
		setEmail(e.target.value);
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		handleSubmit(email);
	};
	const handleChangeSubmit = (e) => {
		e.preventDefault();
		handleChangeForgot();
	};
	return (
		<form className="mt-16">
			<h1 className="text-kupablack text-lg uppercase font-bold flex p-1 mb-4 justify-center align-middle text-center">
				{t("auth.password.reset")}
			</h1>
			<FloatingLabelInput
				className="w-full"
				name="Email"
				onChange={handleEmail}
				value={email}
				type="email"
				autoComplete="email"
			/>

			<div className="text-kupawhite text-center mt-16 grid grid-cols-2">
				<div
					onClick={handleChangeSubmit}
					className="bg-slate-400 rounded-full p-3 text-kupawhite font-medium 
						text-md hover:bg-slate-500 ease-in duration-200 transition-colors cursor-pointer"
				>
					{t("cancel")}
				</div>
				<button
					onClick={handleFormSubmit}
					type="submit"
					id="submit"
					className="bg-kupablue rounded-full p-3 text-kupawhite font-medium 
						text-md hover:bg-kupabluedark ease-in duration-200 transition-colors ml-2"
				>
					{t("send")}
				</button>
			</div>
		</form>
	);
}

export default ForgotPassword;
