import classNames from "classnames";
import React from "react";
const WhiteBlock = ({ children, className, flexRow = false }) => {
	const hasHeightClass = /h-/.test(className);
	return (
		<div
			className={classNames(
				"rounded-[5px] shadow-md bg-white flex ",
				{ "flex-row": flexRow, "flex-col": !flexRow },
				!hasHeightClass && "h-full",
				className
			)}
		>
			{children}
		</div>
	);
};
export default WhiteBlock;
