import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import WhiteBlockDiv from "../../../common/layouts/Global/WhiteBlockDiv";
import Table from "../../../common/layouts/tables/Table";
import { FetchErrors } from "../../requests/errors/Error";
import ErrorFilters from "../filters/ErrorFilters";

function ErrorTable() {
	const [filters, setFilters] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const { t } = useTranslation();

	const { data, isFetching, refetch } = useQuery([`errors`], {
		queryFn: () => FetchErrors(filters, currentPage, 30),
	});

	const { data: errors, pagination } = data || {};

	const handleNewFilters = async (newFilters) => {
		setCurrentPage(1);
		setFilters(newFilters);
	};

	useEffect(() => {
		refetch();
	}, [filters, currentPage, refetch]);

	const columns = [t("type"), t("payload"), t("station"), t("date")];

	const rows = errors?.map((item) => {
		return [
			item.error_type.key,
			item.payload ?? "N/A",
			item.machine?.machine_name?.private_name || "N/A",
			moment(item.created_at).format("YYYY-MM-DD HH:mm:ss"),
		];
	});

	return (
		<WhiteBlockDiv>
			<div className="w-full h-full mt-4 mb-2">
				<ErrorFilters handleNewFilters={handleNewFilters} />
				<Table
					columns={columns}
					rows={rows}
					isLoading={isFetching}
					pagination={pagination}
					currentPage={currentPage}
					handlePageChange={setCurrentPage}
				/>
			</div>
		</WhiteBlockDiv>
	);
}

export default ErrorTable;
