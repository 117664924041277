import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import Tree from "../Tree/Tree";
import Logo from "../logos/Logo";
import NavigationPopup from "../popup/NavigationPopup";
import DocButton from "./DocButton";
import PageHeader from "./PageHeader";

const Sidebar = forwardRef(
	(
		{
			isMobile,
			size,
			setNavheight,
			setOpenModal,
			openModal,
			handleClickInsideModal,
		},
		ref
	) => {
		const divRef = useRef(null);
		const updateDivHeight = () => {
			if (divRef.current) {
				const height = divRef.current.getBoundingClientRect().height;
				setNavheight(height);
			}
		};

		useEffect(() => {
			updateDivHeight();

			const handleResize = () => {
				updateDivHeight();
			};

			window.addEventListener("resize", handleResize);

			return () => {
				window.removeEventListener("resize", handleResize);
			};
		}, [divRef.current]);

		const [openChoicePopup, setOpenChoicePopup] = useState(false);

		const HandleopenChoicePopup = () => {
			setOpenChoicePopup(true);
		};

		const HandlecloseChoicePopup = () => {
			setOpenChoicePopup(false);
		};

		return (
			<aside
				ref={divRef}
				className={classNames(
					"flex justify-between h-full px-3 z-10 py-2  ",
					{
						"flex-row": isMobile,
						"flex-col": !isMobile,
					}
				)}
			>
				{isMobile && (
					<div
						ref={ref}
						className="flex justify-start w-[50px] p-[0px] items-center text-2xl"
						onClick={() => {
							setOpenModal(!openModal);
						}}
					>
						<FontAwesomeIcon icon={faBars} />
					</div>
				)}
				<div className="flex justify-center items-center rounded-[5px] mobile:w-auto mobile:h-auto bg-white mobile:bg-transparent shadow-sidebar mobile:shadow-none">
					<Logo
						actionLogo={() => {
							HandleopenChoicePopup();
							handleClickInsideModal();
						}}
						isMobile={isMobile}
					/>

					{!isMobile && (
						<div className="mobile:absolute right-0 m-3">
							<FontAwesomeIcon
								className="cursor-pointer"
								onClick={HandleopenChoicePopup}
								icon={faBars}
								color="#00acd3"
								size="lg"
							/>
						</div>
					)}
				</div>

				{!isMobile && (
					<div className="flex flex-col h-full justify-between">
						<Tree />
						<DocButton navbar={true} />
					</div>
				)}

				{isMobile && (
					<div
						className={classNames(
							"flex flex-row-reverse items-center justify-center align-middle p-[5px]"
						)}
					>
						<PageHeader isMobile={isMobile} />
					</div>
				)}

				<NavigationPopup
					open={openChoicePopup}
					closeMethod={HandlecloseChoicePopup}
				/>
			</aside>
		);
	}
);

export default Sidebar;
