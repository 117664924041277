import React, { useState, createContext } from "react";

export const TreeContext = createContext({});

export const TreeProvider = ({ children }) => {
	const [updateTree, setUpdateTree] = useState(0);
	const [groupontree, setGroupontree] = useState(0);
	const [lastSelected, setLastSelected] = useState({ type: "", id: 0 });
	return (
		<TreeContext.Provider
			value={{
				updateTree,
				setUpdateTree,
				groupontree,
				setGroupontree,
				lastSelected,
				setLastSelected,
			}}
		>
			{children}
		</TreeContext.Provider>
	);
};
