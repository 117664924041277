import {
	FacebookAuthProvider,
	signInWithPopup,
	updateProfile,
} from "firebase/auth";
import auth from "../../../common/config/firebase";

const loginWithFacebook = async () => {
	const provider = new FacebookAuthProvider();

	provider.addScope("public_profile");
	provider.addScope("email");

	const signedIn = await signInWithPopup(auth, provider);

	const fbauth = FacebookAuthProvider.credentialFromResult(signedIn);

	const response = await fetch(
		`https://graph.facebook.com/me?access_token=${fbauth.accessToken}&fields=id,name,picture.type(large)`
	);

	const { picture } = await response.json();

	await updateProfile(auth.currentUser, {
		photoURL: picture.data.url,
	});
};

export { loginWithFacebook };
