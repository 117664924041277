import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function DeleteIcon({ color, size, action }) {
	return (
		<div
			style={{
				backgroundColor: "white",
				borderRadius: "30px",
				display: "flex",
			}}
		>
			<FontAwesomeIcon
				icon={faCircleXmark}
				size={size}
				color={color}
				onClick={action}
			/>
		</div>
	);
}
export default DeleteIcon;
