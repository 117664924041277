import { useEffect, useState } from "react";
import moment from "moment";
import Filter from "./Filter";

function Filters({ availableFilters, handleNewFilters }) {
	const [filters, setFilters] = useState([]);
	const [editingFilters, setEditingFilters] = useState([]);

	const [oldFormattedFilters, setOldFormattedFilters] = useState([]);

	const buildOutputFilters = (filters) => {
		const isIsoDate = (date) => {
			const isoDatePattern =
				/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z?$/;
			return isoDatePattern.test(date);
		};

		const addToObject = (path, value, obj) => {
			if (
				!value ||
				value?.length === 0 ||
				(isIsoDate(value) && !moment(value).isAfter("2000-01-01"))
			)
				return;

			const lastKey = path.pop();
			const lastObj = path.reduce(
				(obj, key) => (obj[key] = obj[key] || {}),
				obj
			);
			lastObj[lastKey] = value;
		};

		const filterObject = {};

		filters.forEach((filter) => {
			const { type, sql } = availableFilters.find(
				(f) => f.name === filter.name
			);

			switch (type) {
				case "date":
					switch (filter.data.operator) {
						case "between":
							const [firstYear, firstMonth, firstDay] = (
								filter.data?.firstValue ?? ""
							)
								.split("-")
								.map(Number);

							const firstValue = new Date(
								firstYear,
								firstMonth - 1,
								firstDay
							);
							firstValue.setHours(0, 0, 0, 0);

							const [secondYear, secondMonth, secondDay] = (
								filter.data?.secondValue ?? ""
							)
								.split("-")
								.map(Number);
							const secondValue = new Date(
								secondYear,
								secondMonth - 1,
								secondDay
							);
							secondValue.setHours(23, 59, 59, 999);

							addToObject(
								[...sql, "gte"],
								firstValue.toISOString(),
								filterObject
							);

							addToObject(
								[...sql, "lte"],
								secondValue.toISOString(),
								filterObject
							);
							break;
						case "=":
							const [year, month, day] = (
								filter.data?.firstValue ?? ""
							)
								.split("-")
								.map(Number);

							const startDate = new Date(year, month - 1, day);
							startDate.setHours(0, 0, 0, 0);

							const endDate = new Date(year, month - 1, day);
							endDate.setHours(23, 59, 59, 999);

							addToObject(
								[...sql, "gte"],
								startDate.toISOString(),
								filterObject
							);

							addToObject(
								[...sql, "lte"],
								endDate.toISOString(),
								filterObject
							);
							break;

						case ">":
							const [yeargte, monthgte, daygte] = (
								filter.data?.firstValue ?? ""
							)
								.split("-")
								.map(Number);
							addToObject(
								[...sql, "gt"],
								new Date(
									yeargte,
									monthgte - 1,
									daygte
								).toISOString(),
								filterObject
							);
							break;

						case "<":
							const [yearlte, monthlte, daylte] = (
								filter.data?.firstValue ?? ""
							)
								.split("-")
								.map(Number);
							addToObject(
								[...sql, "lt"],
								new Date(
									yearlte,
									monthlte - 1,
									daylte
								).toISOString(),
								filterObject
							);
							break;

						case "in_the_last":
							const momentDate = moment().subtract(
								filter.data.firstValue,
								filter.data.secondValue
							);

							if (!moment().isSame(momentDate))
								addToObject(
									[...sql, "gt"],
									momentDate.toDate().toISOString(),
									filterObject
								);
							break;

						default:
							break;
					}
					break;

				case "number":
					switch (filter.data.operator) {
						case "between":
							addToObject(
								[...sql, "gte"],
								Number(filter.data.firstValue),
								filterObject
							);

							addToObject(
								[...sql, "lte"],
								Number(filter.data.secondValue),
								filterObject
							);
							break;
						case "=":
							addToObject(
								[...sql, "equals"],
								Number(filter.data.firstValue),
								filterObject
							);
							break;

						case ">":
							addToObject(
								[...sql, "gt"],
								Number(filter.data.firstValue),
								filterObject
							);
							break;

						case "<":
							addToObject(
								[...sql, "lt"],
								Number(filter.data.firstValue),
								filterObject
							);
							break;

						default:
							break;
					}
					break;

				case "string":
					switch (filter.data.operator) {
						case "contains":
							addToObject(
								[...sql, "contains"],
								filter.data.value,
								filterObject
							);
							break;

						case "equals":
							addToObject(
								[...sql, "equals"],
								filter.data.value,
								filterObject
							);
							break;

						default:
							break;
					}
					break;

				case "dropdown":
					addToObject(
						[...sql, "equals"],
						filter.data.value,
						filterObject
					);
					break;

				case "checkbox":
					if (filter.data.custom) {
						const customFilter = filter.data.custom;
						addToObject([...customFilter.sql], {}, filterObject);
					} else {
						addToObject(
							[...sql, "in"],
							filter.data.value,
							filterObject
						);
					}
					break;
				default:
					break;
			}
		});

		if (
			JSON.stringify(oldFormattedFilters) === JSON.stringify(filterObject)
		)
			return;
		handleNewFilters(filterObject);
		setOldFormattedFilters(filterObject);
	};

	const handleChange = (filter, data) => {
		const newFilters = [...editingFilters];

		const index = newFilters.findIndex((f) => f.name === filter.name);

		if (index === -1)
			newFilters.push({
				...filter,
				data,
			});
		else newFilters[index].data = data;

		setEditingFilters(newFilters);
	};

	const handleSave = (filter) => {
		const data = currentEditingFilterData(filter);

		if (
			(!data.value &&
				!data.firstValue &&
				!data.secondValue &&
				data.custom === undefined) ||
			(data.value?.length === 0 && data.custom === undefined)
		)
			handleDelete(filter);
		else setFilters(editingFilters);
	};

	const handleDelete = (filter) => {
		setFilters(filters.filter((f) => f.name !== filter.name));
		setEditingFilters(editingFilters.filter((f) => f.name !== filter.name));
	};

	const currentEditingFilterData = (filter) =>
		editingFilters.find((f) => f.name === filter.name)?.data ?? {};

	const currentFilterData = (filter) =>
		filters.find((f) => f.name === filter.name)?.data ?? {};

	useEffect(() => {
		buildOutputFilters(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	return (
		<div>
			<div className="flex justify-between">
				<div className="flex flex-wrap">
					{availableFilters.map((filter, index) => (
						<Filter
							key={index}
							filter={filter}
							currentEditingFilterData={currentEditingFilterData(
								filter
							)}
							currentFilterData={currentFilterData(filter)}
							handleChange={(e) => handleChange(filter, e)}
							handleSave={() => handleSave(filter)}
							handleDelete={() => handleDelete(filter)}
						/>
					))}
				</div>
			</div>
		</div>
	);
}

export default Filters;
