import { requestAPI } from "../../../common/requests/RequestAPI";
import URL from "../../../common/requests/UrlsEnum";

export async function FetchDiscounts(groupId) {
	const discounts = await requestAPI(
		`${URL.GROUP}/${groupId}/discount`,
		"GET"
	);
	return discounts;
}

export async function CreateDiscount(
	groupId,
	description,
	startDate,
	endDate,
	value,
	code,
	isMachineChecked,
	isApplicationChecked,
	selectedIds,
	users
) {
	const data = await requestAPI(`${URL.DISCOUNT}`, "POST", {
		group_id: groupId,
		description: description,
		start_date: startDate,
		end_date: endDate,
		discount_value: value,
		discount_code: code,
		available_machine: isMachineChecked,
		available_app: isApplicationChecked,
		machine_ids:
			selectedIds.length === 1 && selectedIds[0] === "all"
				? []
				: selectedIds,
		users,
	});
	return data;
}

export async function DeleteDiscount(discountId) {
	await requestAPI(`${URL.DISCOUNT}/${discountId}`, "DELETE", {});
}

export async function ModifyDiscount(
	discountId,
	description,
	startDate,
	endDate,
	code,
	isMachineChecked,
	isApplicationChecked,
	selectedIds,
	users
) {
	const data = await requestAPI(`${URL.DISCOUNT}/${discountId}`, "PUT", {
		description: description,
		start_date: startDate,
		end_date: endDate,
		discount_code: code,
		available_machine: isMachineChecked,
		available_app: isApplicationChecked,
		machine_ids:
			selectedIds.length === 1 && selectedIds[0] === "all"
				? []
				: selectedIds,
		users,
	});
	return data;
}
