import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import CustomButton from "../../../../common/layouts/Global/CustomButton";
import Dropdown from "../../../../common/layouts/Global/Dropdown";
import FloatingLabelInput from "../../../../common/layouts/Global/InputFloatingLabel";
import PopupHeadTitle from "../../../../common/layouts/Global/PopupHeadTitle";
import { RoundButtonXMarkAbsolute } from "../../../../common/layouts/Global/RoundButton";
import { getSequences } from "../../../requests/video/videoRequest";

const getCurrentDateHour = () => {
	const currentDate = new Date();
	const yyyy = currentDate.getFullYear();
	const mm = String(currentDate.getMonth() + 1).padStart(2, "0");
	const dd = String(currentDate.getDate()).padStart(2, "0");
	const hh = String(currentDate.getHours()).padStart(2, "0");
	const min = String(currentDate.getMinutes()).padStart(2, "0");
	return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
};

function CreateGroupVideoSchedulePopup({
	open,
	closeMethod,
	createMethod,
	groupId,
}) {
	const { t } = useTranslation();
	const startDate = useRef(null);
	const endDateRef = useRef(null);
	const [start, setStart] = useState(getCurrentDateHour());

	const { data: sequences } = useQuery([`group-${groupId}-sequences`], {
		queryFn: () => getSequences(groupId),
	});
	const [selectedSequence, setSelectedSequence] = useState(null);
	useEffect(() => {
		if (open) {
			setStart(getCurrentDateHour());
			setSelectedSequence(null);
			if (endDateRef.current) {
				endDateRef.current.value = "";
			}
		}
	}, [open]);
	return (
		<Popup
			open={open}
			onClose={closeMethod}
			contentStyle={{
				borderRadius: "0.75rem",
				width: "auto",
				padding: "10px",
			}}
			className="relative"
		>
			<RoundButtonXMarkAbsolute functionCall={closeMethod} />

			<PopupHeadTitle text={t("create.schedule.group")} />

			<div className="text-center p-1 mt-2">
				<div
					className="m-auto "
					style={{
						gap: "0px",
						//display: "flex",
						//flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Dropdown
						//className="mb-1 w-44"
						customTitle={t(`sequence.select`)}
						name={selectedSequence && t(`sequence`)}
						list={sequences?.map((sequence) => ({
							title: sequence.name,
							onClick: () => setSelectedSequence(sequence.id),
						}))}
					/>

					<FloatingLabelInput
						//className="w-44"
						reference={startDate}
						min={getCurrentDateHour()}
						type="datetime-local"
						defaultValue={getCurrentDateHour(new Date())}
						onChange={(e) => {
							setStart(e.target.value);
						}}
						name={t("date.start")}
					/>
					<FloatingLabelInput
						//className="w-44"
						type="datetime-local"
						reference={endDateRef}
						min={start}
						name={t("date.end")}
					/>
				</div>
				<div className="flex justify-end">
					<CustomButton
						className=" w-40 mt-1"
						text={t("create")}
						onClick={() => {
							const groupName = startDate.current.value;
							if (groupName !== "") {
								createMethod(
									selectedSequence,
									start,
									endDateRef.current.value === ""
										? null
										: endDateRef.current.value
								);
							}
						}}
					/>
				</div>
			</div>
		</Popup>
	);
}
export default CreateGroupVideoSchedulePopup;
