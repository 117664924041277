import { useTranslation } from "react-i18next";
import WhiteBlockDiv from "../../../common/layouts/Global/WhiteBlockDiv";
import Table from "../../../common/layouts/tables/Table";
import { GetRecipeImg } from "../../../common/util/RecipeUtil";
function Boissons({ stats = [], text }) {
	const { t } = useTranslation();
	const isValidStats = stats && typeof stats === "object";
	const sumTotal = stats.reduce((total, el) => total + el.count, 0);

	const columns = [t("recipe"), "%", "nombre"];

	const rows = stats?.map((stats) => {
		return [
			<div className="flex items-center">
				<img
					src={GetRecipeImg(stats.key)}
					alt={stats.key}
					className="h-8 w-8 mr-2 object-contain saturate-50 mix-blend-multiply"
				/>
				<p className="m-2">{t(stats.key)}</p>
			</div>,
			((stats.count / sumTotal) * 100).toFixed(1),
			stats.count.toLocaleString(),
		];
	});
	return (
		isValidStats && (
			<WhiteBlockDiv text={text} className={"mb-2 lg:mb-0"}>
				<Table
					className={
						"justify-center w-full max-h-300 h-fit md:max-h-400 overflow-auto"
					}
					columns={columns}
					rows={rows}
				/>
			</WhiteBlockDiv>
		)
	);
}

export default Boissons;
