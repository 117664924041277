import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function AddContainerBox({ addContainer, offline }) {
	const { t } = useTranslation();

	return (
		<button
			className="bg-opacity-100 border-secondary dark:bg-thirddark dark:border-secondarydark rounded-lg border-2 shadow-md p-2 text-lg w-full bg-kupablue/80 hover:bg-kupablue/50 text-white  hover:shadow-md"
			onClick={addContainer}
		>
			<FontAwesomeIcon
				icon={faPlus}
				className="justify-center text-center"
				size="xl"
			/>
			<div>{t("settings.sizes.add")}</div>
		</button>
	);
}

export default AddContainerBox;
