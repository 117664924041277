import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import CustomButton from "../../../../common/layouts/Global/CustomButton";
import PopupHeadTitle from "../../../../common/layouts/Global/PopupHeadTitle";

function DeletePopup({
	open,
	closeMethod,
	deleteMethod,
	typeOfDelete,
	customDiv,
	nested,
}) {
	const { t } = useTranslation();
	return (
		<Popup
			nested={nested}
			open={open}
			onClose={closeMethod}
			contentStyle={{
				borderRadius: "0.75rem",
				width: "auto",
				padding: "10px",
			}}
		>
			<PopupHeadTitle text={t("delete." + typeOfDelete + ".header")} />

			<div className=" text-left text-xs sm:text-base p-7 pl-2 text-four">
				{t("delete." + typeOfDelete + ".title")}
				<p className=" text-four ">{t("delete.subtitle")}</p>
				{customDiv}
			</div>

			<div className="flex flex-row-reverse mt-2 justify-start gap-2">
				<CustomButton
					className="w-32 "
					//type={"cancel"}
					onClick={() => {
						closeMethod();
					}}
					text={t("false")}
				/>
				<CustomButton
					className="w-32"
					type={"cancel"}
					onClick={(e) => {
						deleteMethod();
						closeMethod();
					}}
					text={t("true")}
				/>
			</div>
		</Popup>
	);
}
export default DeletePopup;
